import { Box, Typography, Button } from "@mui/material";
import React from "react";
import TextInput from "../../../../globalComponents/global_inputs/TextInput";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import TextArea from "../../../../globalComponents/global_inputs/TextArea";
import theme from "../../../../../theme";
import { useDispatch, useSelector } from "react-redux";
import { setServiceDetails } from "../../../../../features/slice/addServiceSlice";
const validationSchema = Yup.object().shape({
  title: Yup.string()
    .min(3, "Title must be 3 Character")
    .max(100, "Title Should be less then 100 Character")
    .required("Course title is required"),
  mode: Yup.string().required("Please check one of above"),
  classDuration: Yup.string().required("Specify class duration"),
  courseDuration: Yup.string().required("Course duration is required"),
  // classDays: Yup.array().required("select the days"),
  courseFee: Yup.string()
    .matches(/^\d+$/, "Only numeric value is allowed")
    .required("Enter the course fee")
    .max(10, "Course fee must be less than 10-digit "),

  description: Yup.string()
    .max(1000, "Description should be less than 1000 Character")
    .required("Description is required"),
});

const EnterDetails = ({ activeStep, setActiveStep }) => {
  let dispatch = useDispatch();
  const { serviceDetails } = useSelector((state) => state.addService);
  const onSubmit = (values) => {
    let obj = {
      service: {
        ...serviceDetails,
        ...values,
      },
    };
    dispatch(setServiceDetails(obj));
    handleNext(activeStep);
  };
  const initialValues = {
    // Define your form initial values here
    title: serviceDetails?.title || "",
    mode: serviceDetails?.mode || "",
    classDuration: serviceDetails?.classDuration || "",
    courseDuration: serviceDetails?.courseDuration || "",
    courseFee: serviceDetails?.courseFee || "",
    classDays: serviceDetails?.classDays || null,
    description: serviceDetails?.description || "",
  };
  const handleNext = () => {
    setActiveStep((prevActiveStep) => {
      return prevActiveStep + 1;
    });
  };

  const handleBack = (e) => {
    setActiveStep((prevActiveStep) => {
      return prevActiveStep - 1;
    });
  };

  const inputStyles = {
    width: "100%",
    minWidth: "280px",
    borderRadius: "30px",
    height: "60px",
    border: `1px solid #DCDCDC`,
    padding: "8px",
  };
  const radioStyles = {
    border: `1px solid ${theme.palette.primary.main}`,
    width: "50%",
    display: "flex",
    alignItems: "center",
    padding: "20px",
    borderRadius: "30px",
  };

  return (
    <Box>
      <Typography variant="h5">Enter your details</Typography>
      <Box>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={onSubmit}
        >
          <Form>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                gap: "16px",
                mt: "24px",
                maxWidth: "auto !important",
              }}
            >
              <Field
                component={TextInput}
                placeholder="Title"
                type="text"
                name="title"
                label="title"
              />
              {/* radio */}
              <Box>
                <div
                  role="group"
                  aria-labelledby="my-radio-group"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    gap: "20px",
                    height: "60px",
                  }}
                >
                  <label style={radioStyles}>
                    <Field type="radio" name="mode" value="online" />
                    Online
                  </label>
                  <label style={radioStyles}>
                    <Field type="radio" name="mode" value="onsite" />
                    Onsite
                  </label>
                </div>
              </Box>
              {/* course duration  */}
              <Box>
                <label style={{ display: "block" }}>Course Duration</label>
                <Field
                  name="courseDuration"
                  as="select"
                  className="my-select"
                  style={inputStyles}
                >
                  <option value="" label="Select Course Duration" />
                  <option value="1 Month" label="1 month" />
                  <option value="3 Months" label="3 months" />
                  <option value="6 Months" label="6 months" />
                  <option value="1 Year" label="1 year" />
                </Field>
              </Box>
              <Box>
                <label style={{ display: "block" }}>Class Days</label>
                <div role="group" aria-labelledby="checkbox-group">
                  <label>
                    <Field type="checkbox" name="classDays" value="Monday" />
                    Monday
                  </label>
                  <label>
                    <Field type="checkbox" name="classDays" value="Tuesday" />
                    Tuesday
                  </label>
                  <label>
                    <Field type="checkbox" name="classDays" value="Wednesday" />
                    Wednesday
                  </label>
                  <label>
                    <Field type="checkbox" name="classDays" value="Thursday" />
                    Thursday
                  </label>
                  <label>
                    <Field type="checkbox" name="classDays" value="Friday" />
                    Friday
                  </label>
                  <label>
                    <Field type="checkbox" name="classDays" value="Saturday" />
                    Saturday
                  </label>
                  <label>
                    <Field type="checkbox" name="classDays" value="Sunday" />
                    Sunday
                  </label>
                </div>
              </Box>
              <Box>
                <label
                  style={{
                    display: "block",
                  }}
                >
                  Class Duration
                </label>
                <Field
                  name="classDuration"
                  as="select"
                  className="my-select"
                  style={inputStyles}
                >
                  <option value="" label="Select Class Duration" />
                  <option value="30 Minutes" label="30 minutes" />
                  <option value="45 Minutes" label="45 minutes" />
                  <option value="1 hour" label="1 hour" />
                  <option value="90 Minutes" label="90 minutes" />
                </Field>
              </Box>
              <Field
                component={TextInput}
                placeholder="Course Fee (PKR)"
                type="text"
                name="courseFee"
                label="Course Fee"
              />
              <Field
                component={TextArea}
                placeholder="Description"
                type="text"
                name="description"
                label="description"
              />
            </Box>
            <Box
              sx={{ display: "flex", justifyContent: "flex-end", mt: "24px" }}
            >
              <Button
                disabled={activeStep === 0}
                onClick={() => handleBack(activeStep)}
                sx={{ padding: "12px 40px", fontSize: "16px" }}
              >
                Back
              </Button>
              <Button
                variant="contained"
                type={"submit"}
                // onClick={() => {
                //   handleNext(activeStep);
                // }}
                sx={{ padding: "12px 40px", color: "white", fontSize: "16px" }}
              >
                Next
              </Button>
            </Box>
          </Form>
        </Formik>
      </Box>
    </Box>
  );
};

export default EnterDetails;
