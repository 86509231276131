import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import useFetch from "../../../../features/hooks/useFetch";
import DashBoardModal from "../../../../components/DashBoard/Modals/DashBoardModal";
import { useEffect } from "react";
import useGetAPI from "../../../../features/hooks/useGetAPI";
import moment from "moment";
import DashBoardSearchInputField from "../../../../components/DashBoard/DashBoardSearchInputField/DashBoardSearchInputField";
import { useSelector } from "react-redux";

export default function DashboardCourses() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [coursesData, setCoursesData] = React.useState([]);
  const { getData } = useGetAPI();
  const { deleteData } = useFetch();
  const [open, setOpen] = React.useState(false);
  const [showMarketData, setShowMarketData] = React.useState(null);
  const [selectedCourseId, setSelectedCourseId] = React.useState(null);
  const openModal = (dataCourse) => {
    setShowMarketData(dataCourse);
    setOpen(true);
  };
  const handleClose = (data) => {
    setOpen(false);
    console.log("close triggered", data);
    updateDataOnDelete(data?.data?.courseId);
  };

  // geting User from the Redux

  const user = useSelector(
    (state) => state.DashBoardProductsSlice.dashBoarduser
  );
  // getting Products from the redux..

  const FilterProducts = useSelector(
    (state) => state.DashBoardProductsSlice.dashBoardcourse
  );
  // here i get the courseId on the click of the handleDeleteOptionClick which is  bydefult in the Mui
  const handleDeleteIconClick = (event, courseId) => {
    setAnchorEl(event.currentTarget);
    // console.log(courseId, "course icon Click");
    setSelectedCourseId(courseId);
  };
  //get course ID from event and hit delete course as admin
  const handleDeleteOptionClick = (courseId) => {
    // console.log(courseId, "Course for deleting on handle Delete button");
    setAnchorEl(null);
    deleteData(`/api/admin/course/${courseId}`, (res) => {
      //after deleting data filter current data and set updated courses data
      updateDataOnDelete(courseId);
    });
  };
  function updateDataOnDelete(courseId) {
    const afterDelete = coursesData.filter((valueData) => {
      return valueData.courseId !== courseId;
    });
    setCoursesData(afterDelete);
  }
  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  // hit courses api on render
  useEffect(() => {
    getData("/api/admin/course", (res) => {
      setCoursesData(res.data);
    });
  }, []);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "20px",
          padding: "15px",
        }}
      >
        <Typography
          component={"span"}
          variant="h2"
          noWrap
          sx={{
            margin: "10px 0",
          }}
        >
          Courses
        </Typography>
        <Divider
          sx={{
            width: "100%",
          }}
        />
        <Box
          sx={{
            margin: "10px 0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DashBoardSearchInputField
            url={"api/admin/course/"}
            cb={setCoursesData}
          />
        </Box>
        {/* here i'm getting the value from redux */}
        <Box>
          {" "}
          {user && user ? (
            <>
              <Typography
                component={"span"}
                variant="h2"
                sx={{
                  padding: "10px 0px",
                }}
              >
                User personal details
              </Typography>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <img
                          style={{
                            width: "100%",
                            maxWidth: "100px",
                            height: "100px",
                            objectFit: "cover",
                            borderRadius: "50%",
                          }}
                          src={
                            user && user.profilePic
                              ? user.profilePic
                              : "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"
                          }
                          alt="UserImage"
                        />
                      </TableCell>
                      <TableCell>
                        <Typography
                          component={"span"}
                          variant="h5"
                          sx={{ marginBottom: "10px", display: "block" }}
                        >
                          Name
                        </Typography>
                        {user ? user.firstName + " " + user.lastName : ""}
                      </TableCell>
                      <TableCell>
                        <Typography
                          component={"span"}
                          variant="h5"
                          sx={{ marginBottom: "10px", display: "block" }}
                        >
                          Email
                        </Typography>
                        {user ? user.email : ""}
                      </TableCell>
                      <TableCell>
                        <Typography
                          component={"span"}
                          variant="h5"
                          sx={{ marginBottom: "10px", display: "block" }}
                        >
                          Status
                        </Typography>
                        {user ? (user.isBlocked ? "Unblock" : "Block") : ""}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            ""
          )}
        </Box>
        {/* end of redux */}
        <Typography
          component={"span"}
          variant="h2"
          sx={{
            padding: "20px 0px",
          }}
        >
          User courses details
        </Typography>

        {FilterProducts && FilterProducts.length > 0 ? (
          <Grid container spacing={"5px"} item>
            {FilterProducts.map((FilterProducts) => {
              return (
                <Grid
                  onClick={() => {
                    openModal(FilterProducts);
                  }}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={4}
                  key={FilterProducts.courseId}
                  marginY={2}
                  style={{
                    width: "100% !important",
                  }}
                >
                  <Card
                    sx={{
                      position: "relative",
                      maxWidth: 345,
                      borderRadius: "15px",
                      paddingBottom: "25px",
                      minHeight: "550px",
                    }}
                  >
                    <img
                      src={FilterProducts.images[0]}
                      style={{
                        width: "100%",
                        borderRadius: "15px 15px 0 0",
                        objectFit: "cover",
                        height: "339px",
                      }}
                      alt="Market"
                    />
                    <CardContent sx={{ padding: "20px" }}>
                      <Typography
                        component={"span"}
                        sx={{ display: "block", wordBreak: "break-all" }}
                        variant="h3"
                        gutterBottom
                      >
                        {FilterProducts.title.length > 25
                          ? FilterProducts.title.substring(0, 25) + "....."
                          : FilterProducts.title}
                      </Typography>
                      <Typography
                        component={"span"}
                        sx={{
                          display: "flex",
                          height: "50px",
                          overflow: "hidden",
                          // bottom: "15px",
                          wordBreak: "break-all",
                        }}
                      >
                        {FilterProducts.description.length > 45
                          ? FilterProducts.description.substring(0, 45) +
                            "....."
                          : FilterProducts.description}
                      </Typography>
                      <Typography
                        component={"span"}
                        sx={{
                          display: "flex",
                          position: "absolute",
                          bottom: "15px",
                        }}
                      >
                        {moment
                          .utc(FilterProducts.createdAt)
                          .local()
                          .startOf("seconds")
                          .fromNow()}
                      </Typography>
                    </CardContent>
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDeleteIconClick(event, FilterProducts.courseId);
                      }}
                      sx={{
                        isolation: "isolate",
                        // mixBlendMode:"difference",
                        color: "white",
                        backgroundColor: "black",
                        filter: "invert(1)",
                        position: "absolute",
                        top: 0,
                        right: 0,
                        margin: "5px 5px 0px 0px",
                      }}
                    >
                      <MoreVertIcon
                      // sx={{
                      //   position: "absolute",
                      //   top: 0,
                      //   right: 0,
                      //   margin: "5px 5px 0px 0px",
                      //   color: "white",
                      //   backgroundColor:"green"
                      // }}
                      // onClick={(event) => {
                      //   event.stopPropagation();
                      //   handleDeleteIconClick(event, FilterProducts);
                      // }}
                      />
                    </IconButton>

                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenu}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem
                        onClick={(event) => {
                          event.stopPropagation();
                          handleDeleteOptionClick(selectedCourseId);
                        }}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        ) : FilterProducts && FilterProducts.length === 0 ? (
          <Typography component={"span"}>No Courses of this User</Typography>
        ) : (
          <Grid container spacing={"5px"} item>
            {coursesData.map((DashMarket) => {
              return (
                <Grid
                  onClick={() => {
                    openModal(DashMarket);
                  }}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={4}
                  key={DashMarket.courseId}
                  marginY={2}
                  style={{
                    width: "100% !important",
                  }}
                >
                  <Card
                    sx={{
                      position: "relative",
                      maxWidth: 345,
                      borderRadius: "15px",
                      minHeight: "550px",
                      paddingBottom: "25px",
                    }}
                  >
                    <img
                      src={DashMarket.images[0]}
                      style={{
                        width: "100%",
                        borderRadius: "15px 15px 0 0",
                        objectFit: "cover",
                        height: "339px",
                      }}
                      alt="Market"
                    />
                    <CardContent sx={{ padding: "20px" }}>
                      <Typography
                        component={"span"}
                        sx={{ display: "block", wordBreak: "break-all" }}
                        gutterBottom
                        variant="h3"
                      >
                        {DashMarket.title.length > 25
                          ? DashMarket.title.substring(0, 25) + "....."
                          : DashMarket.title}
                      </Typography>
                      <Typography
                        component={"span"}
                        sx={{
                          display: "flex",
                          height: "50px",
                          overflow: "hidden",
                          wordBreak: "break-all",
                        }}
                      >
                        {DashMarket.description.length > 45
                          ? DashMarket.description.substring(0, 45) + "....."
                          : DashMarket.description}
                      </Typography>
                      <Typography
                        component={"span"}
                        sx={{
                          display: "flex",
                          position: "absolute",
                          bottom: "15px",
                        }}
                      >
                        {moment
                          .utc(DashMarket.createdAt)
                          .local()
                          .startOf("seconds")
                          .fromNow()}
                      </Typography>
                    </CardContent>
                    <IconButton
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDeleteIconClick(event, DashMarket.courseId);
                      }}
                      sx={{
                        isolation: "isolate",
                        // mixBlendMode:"difference",
                        color: "white",
                        backgroundColor: "black",
                        filter: "invert(1)",
                        position: "absolute",
                        top: 0,
                        right: 0,
                        margin: "5px 5px 0px 0px",
                      }}
                    >
                      <MoreVertIcon
                      // sx={{
                      //   position: "absolute",
                      //   top: 0,
                      //   right: 0,
                      //   margin: "5px 5px 0px 0px",
                      //   color: "white",

                      // }}
                      // onClick={(event) => {
                      //   event.stopPropagation();
                      //   handleDeleteIconClick(event, DashMarket.courseId);
                      // }}
                      />
                    </IconButton>

                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenu}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem
                        onClick={(event) => {
                          event.stopPropagation();
                          handleDeleteOptionClick(selectedCourseId);
                        }}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>
      {open && (
        <DashBoardModal
          dataShow={showMarketData}
          open={open}
          handleClose={handleClose}
        />
      )}
    </>
  );
}
