import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import Card from "../../components/Market/Cards/Card";
import Layout from "../../components/globalComponents/Layout/Layout";
import ButtonComp from "../../components/globalComponents/ButtonComp";
import HeroSection from "../../components/globalComponents/HeroSection";
import SearchInput from "../../components/globalComponents/global_inputs/SearchInput";
import Dropdown from "../../components/globalComponents/Dropdown";
import AddProduct from "../../components/Market/Modals/Add Product/AddProduct";
import ViewProduct from "../../components/Market/Modals/ViewProduct/ViewProduct";
import { top100Films } from "../../components/data";
import useFetch from "../../features/hooks/useFetch";
import { setProductParentCategories } from "../../features/slice/categoriesSlice";
import { useDispatch } from "react-redux";
import theme from "../../theme";
const Market = () => {
  const [flag, setFlag] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("All");
  const [filteredProducts, setFilteredProducts] = useState(null);
  const [productCategoriesList, setProductCategoriesList] = useState([]);
  const [parents, setParents] = useState(null);
  const { fetchData } = useFetch();
  let dispatch = useDispatch();

  let productCat = [];

  //function to fetch all products
  const getProductCategories = async () => {
    await fetchData("/api/product/parent", undefined, (res) => {
      if (res) {
        res?.data?.map((category) => {
          productCat.push(category.name);
        });
        setProductCategoriesList((current) => {
          current = productCat;
          return [...productCategoriesList, ...current];
        });
        setParents(res?.data);
        dispatch(setProductParentCategories({ data: res?.data }));
        setFlag(true);
      }
    });
  };
  const getAllProducts = async () => {
    await fetchData("/api/product", undefined, (res) => {
      setFilteredProducts(res?.data);
    });
  };

  const filterProducts = () => {
    setSelectedCategory(selectedCategory);
    // console.log(selectedCategory, "selected");
    let element;
    for (let index = 0; index < parents?.length; index++) {
      if (selectedCategory === parents[index].name) {
        element = parents[index];
      }
    }
    // console.log(element, "element");
    if (element) {
      //filter products based on selected category
      fetchData(
        `/api/product?parentCategory=${element.productParentCategoryId}`,
        undefined,
        (res) => {
          setFilteredProducts(res?.data);
          // console.log(res?.data);
        }
      );
    } else {
      //get all products
      getAllProducts();
    }
  };

  useEffect(() => {
    getProductCategories();
    getAllProducts();
  }, []);
  useEffect(() => {
    filterProducts();
  }, [selectedCategory]);
  return (
    <>
      <Box
        sx={{
          "& .css-yiavyu-MuiBackdrop-root-MuiDialog-backdrop": {
            backgroundColor: "white !important",
          },
        }}
        
      >
        <HeroSection
        customStyles={{
          height:"245px"
        }}
          title={"Your One-Stop Marketplace for Products"}
          description={`Our marketplace is a digital hub offering a wide range of online courses, from Quranic studies to skill-based learning. Whether you're looking to expand your knowledge or gain new skills, our secure and user-friendly platform has the courses you need. Join our community where education and learning opportunities come together seamlessly.`}
        />
        <Layout title={"Marketplace | SMAC"}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "-30px !important",
              zIndex: 999,
            }}
          >
            <SearchInput
              dropDownData={top100Films}
              inputType="multitag"
              display={false}
              Icondisply={{
                display: "none",
              }}
            />
          </Box>
          <Box sx={{ margin: "25px 0" }}>
            {flag ? (
              <Dropdown
                name={"market"}
                data={productCategoriesList}
                cb={setSelectedCategory}
              />
            ) : null}
          </Box>

          <Box
            sx={{
              margin: "0 auto",
              display: "grid",
              gap: "20px",
              gridTemplateColumns: "24% 24% 24% 24%",
              gridAutoRows: "462px",
              [theme.breakpoints.down("lg")]: {
                gridTemplateColumns: "32% 32% 32%",
              },
              [theme.breakpoints.down("md")]: {
                gridTemplateColumns: "49% 49%",
              },
              [theme.breakpoints.down("sm")]: {
                gridTemplateColumns: "auto",
                justifyItems: "center",
              },
            }}
          >
            {filteredProducts?.length === 0 ? (
              <Typography variant="h4Black">
                There are no products of category {selectedCategory}
              </Typography>
            ) : (
              filteredProducts?.map((cardData, index) => {
                return <Card cardData={cardData} key={index} />;
              })
            )}
          </Box>
          {/* this is the model for view single product details */}
          <ViewProduct />
          {/* this is the model for adding a product */}
          {/* <AddProduct open={productOpen} setOpen={setProductOpen} /> */}
        </Layout>
      </Box>
    </>
  );
};

export default Market;
