import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  serviceDetails: null,
  serviceImages: null,
};

const addServiceSlice = createSlice({
  name: "service",
  initialState,
  reducers: {
    setServiceDetails: (state, action) => {
      state.serviceDetails = action.payload.service;
    },
    setServiceImages: (state, action) => {
      state.serviceImages = action.payload.service;
    },
  },
});

export const { setServiceDetails, setServiceImages } = addServiceSlice.actions;
export default addServiceSlice.reducer;
