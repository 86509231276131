import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";

import { useNavigate, useLocation } from "react-router-dom";
import Layout from "./../../../../components/globalComponents/Layout/Layout";
import CreateProductCategory from "./ProductCategory";

function formatString(str) {
  return str.trim().replace(/\s+/g, "-");
}
//multistate tabs labels
const tabLabels = ["Product", "Course"];

const labelToIndex = tabLabels.reduce((acc, label, index) => {
  acc[formatString(label)] = index.toString();
  return acc;
}, {});

const ProfileUpdate = () => {
  const [value, setValue] = React.useState("0");
  // tabs on change event handler
  const handleChange = (event, newValue) => {
    setValue(newValue);
    //add params for
  };

  let tabStyles = {
    borderBottom: "none",
    textDecoration: "none",
    fontSize: "14px",
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "flex-end",
        gap: "100px",

        width: "100%",
      }}
    >
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <TabContext value={value}>
          <Box
            sx={{
              width: "100%",

              background: "white",

              padding: "10px",

              "@media (max-width:650px)": {
                overflowX: "scroll",
                "::-webkit-scrollbar": {
                  height: "5px",
                  background: "transparent",
                },
              },
            }}
          >
            <TabList
              onChange={handleChange}
              // centered
              sx={{
                display: "flex",

                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Tab label="Product" value="0" sx={tabStyles} />
              <Tab label="Course" value="1" sx={tabStyles} />
            </TabList>
          </Box>
          <Box sx={{ width: "100%" }}>
            <TabPanel value="0">
              <CreateProductCategory />
            </TabPanel>
            <TabPanel value="1">course</TabPanel>
          </Box>
        </TabContext>
      </Box>
    </Box>
  );
};

export default ProfileUpdate;
