import { Box, Typography } from "@mui/material";
import React from "react";
import theme from "../../theme";
import ImageComp from "./ImageComp";

const ProfilePicture = ({ src, firstName, sx, innerBox }) => {
  let initials;
  if (!src && firstName) {
    let firstInitial = firstName.charAt(0).toUpperCase();
    initials = firstInitial;
  }
  const iconColors = [
    theme.palette.primary.main,
    theme.palette.hearts.blue,
    theme.palette.hearts.pink,
    theme.palette.hearts.black,
    theme.palette.hearts.red,
    theme.palette.hearts.yellow,
  ];
  const iconColor = iconColors[Math.floor(Math.random() * iconColors.length)];
  return (
    <>
      {src ? (
        <ImageComp
          src={src}
          alt={"story_image"}
          sx={{
            borderRadius: "50%",
            height: "61px",
            width: "61px",
            backgroundSize: "cover",
            ...sx,
          }}
        />
      ) : (
        <Box
          sx={{
            borderRadius: "50%",
            height: "60px",
            width: "60px",
            background: `${iconColor}20`,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            ...sx,
          }}
        >
          <Box
            sx={{
              borderRadius: "50%",
              height: "50px",
              width: "50px",
              background: iconColor,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              ...innerBox,
            }}
          >
            <Typography
              sx={{ fontSize: "1.75rem", fontWeight: 600, color: "white" }}
            >
              {initials}
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ProfilePicture;
