import React from "react";
import Layout from "./../../components/globalComponents/Layout/Layout";
import AboutUsContent from "../../components/globalComponents/AboutUsContent";
import Banner from "../../components/globalComponents/Banner";
import { Box, Typography } from "@mui/material";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import ImageComp from "../../components/globalComponents/ImageComp";
const AboutUs = () => {
  return (
    <>
      <Banner
        image={"assets/photos/profile-background.png"}
        title={"About Us"}
        text={`Welcome to SMAC, where innovation drives connection, growth, and opportunity. At SMAC, we are dedicated to transforming how you network, learn, and engage with the marketplace. Our platform is designed to seamlessly integrate three essential modules that cater to your professional and personal development needs.`}
      />
      <Layout>
        <Box>
          <AboutUsContent
            title={"Social Media"}
            imageSrc={"assets/photos/socialBanner.png"}
            details={`Connect with industry professionals, expand your network, and engage in meaningful conversations. SMAC’s social media features are crafted to help you build valuable connections, enhance your influence, and collaborate with peers. Experience a dynamic environment where professional networking meets innovation`}
          />
          <AboutUsContent
            imageSrc={"assets/photos/marketBanner.png"}
            title={"Market"}
            reverse={true}
            details={`Discover a versatile marketplace where you can find unique items, sell your products, and offer your skills. SMAC connects buyers, sellers, and freelancers in a seamless experience, providing opportunities to explore, transact, and showcase your expertise.`}
          />
          <AboutUsContent
            imageSrc={"assets/photos/schoolBanner.png"}
            title={"School"}
            details={`Elevate your career with our comprehensive learning platform. SMAC offers a range of expert-led courses designed to help you acquire new skills and advance your professional journey. From interactive classes to certification opportunities, we provide the resources you need to succeed and grow.`}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            maxWidth: "1052px",
            margin: "0 auto",
            textAlign: "center",
          }}
        >
          <Box sx={{ display: "flex", marginBottom: "24px" }}>
            <FormatQuoteIcon
              sx={{
                color: "#FFCA41",
                transform: "rotate(180deg)",
              }}
            />
            <Typography variant="uploadForm">
              Stop wasting time, stop sweeping reels, stop watching netflix,
              lets earn money, cause money talks <br />
              "button dabao, jutay na ghisao"
              <FormatQuoteIcon sx={{ color: "#FFCA41" }} />
            </Typography>
          </Box>
          <Box>
            <ImageComp
              src={"assets/photos/ceo.png"}
              styles={{ marginBottom: "12px" }}
            />
            <Box>
              <Typography variant="bold24Black">Sher Alam</Typography>
              <Typography variant="subHeader" sx={{ display: "block" }}>
                Founder & CEO
              </Typography>
            </Box>
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export default AboutUs;
