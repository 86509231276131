import { Box, Button, Dialog, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import theme from "../../../theme";
import { Field, Formik, Form } from "formik";
import * as Yup from "yup";
import TextInput from "../../globalComponents/global_inputs/TextInput";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import ButtonComp from "../../../components/globalComponents/ButtonComp";
import Cards from "react-credit-cards-2";
import {
  JazzCashIcon,
  VisaAndMasterCardIcon,
} from "../../globalComponents/constants.js";
import "react-credit-cards-2/dist/es/styles-compiled.css";
import useFetch from "../../../features/hooks/useFetch.js";
import { toast } from "react-toastify";
const AddPaymentMethod = ({
  isAddDetailsOpen,
  setIsAddDetailsOpen,
  courseId,
  courseFee,
}) => {
  const { postData } = useFetch();
  const [state, setState] = useState({
    number: "",
    cvc: "",
    name: "",
    expiry: "",
    focus: "",
  });

  const [value, setValue] = React.useState("1");

  const handleInputChangeNumber = (evt) => {
    const { name, value } = evt.target;
    if (name === "number") {
      let number = evt.target.value;
      // Enforce a maximum limit of 16 digits
      if (number.length > 16) {
        number = number.slice(0, 16);
      }

      setState((prev) => ({ ...prev, [name]: number }));
    } else if (name === "cvc") {
      let cvc = evt.target.value;

      // Enforce a maximum limit of 19 digits
      if (cvc.length > 3) {
        cvc = cvc.slice(0, 3);
      }
      setState((prev) => ({ ...prev, [name]: cvc }));
    } else if (name === "expiry") {
      let expiry = evt.target.value;

      // Enforce a maximum limit of 19 digits
      if (expiry.length > 4) {
        expiry = expiry.slice(0, 4);
      }
      setState((prev) => ({ ...prev, [name]: expiry }));
    } else {
      setState((prev) => ({ ...prev, [name]: value }));
    }
  };

  const handleInputFocusNumber = (evt) => {
    setState((prev) => ({ ...prev, focus: evt.target.name }));
  };

  const handleRegisterCard = () => {
    if (state.number && state.cvc && state.name && state.expiry) {
      postData(
        `/api/course/${courseId}/orders`,
        undefined,
        undefined,
        undefined,
        undefined,
        (res) => {
          setIsAddDetailsOpen(false);
        }
      );
    } else {
      toast.error("Please select all fields or check credentials properly");
    }
  };

  const handleChange = (event, newValue) => {
    event.preventDefault();
    setValue(newValue);
    console.log(newValue, "new value");
  };
  let cardInputStyles = {
    height: "45px",
    width: "100%",
    marginTop: "8px",
    borderRadius: "6px",
    border: "1px solid #787878",
    padding: "2px 10px",
    "&:focus": {
      border: "none",
      outline: "none",
    },
  };
  let labelStyle = {
    fontSize: "12px",
    fontWeight: 600,
  };

  return (
    <>
      <Dialog
        sx={{
          // maxWidth: "828px",
          minWidth: "350x",
          margin: "0 auto",
          borderRadius: "12px",
        }}
        open={isAddDetailsOpen}
        onClose={() => setIsAddDetailsOpen(!isAddDetailsOpen)}
      >
        <Box sx={{ width: "100%", padding: "24px 0px", background: "#F9F9F9" }}>
          <TabContext value={value}>
            <Box
              sx={{
                width: "100%",
                "@media (max-width:650px)": {
                  overflowX: "scroll",
                  "::-webkit-scrollbar": {
                    height: "5px",
                    background:
                      "transparent" /* Optional: Set the scrollbar background color to match the container */,
                  },
                },
              }}
            >
              <TabList onChange={handleChange} centered>
                <Tab icon={<VisaAndMasterCardIcon />} value="1" />
                <Tab icon={<JazzCashIcon />} value="2" />
              </TabList>
            </Box>
            <Box sx={{ width: "100%", maxWidth: "828px" }}>
              {value === "1" && (
                <TabPanel value="1">
                  <Box
                    sx={{
                      display: "flex",
                      gap: "25px",
                      flexDirection: "column",
                      paddingTop: "16px",
                    }}
                  >
                    <Cards
                      number={state.number}
                      expiry={state.expiry}
                      cvc={state.cvc}
                      name={state.name}
                      focused={state.focus}
                    />
                    <form
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                        padding: "12px",
                      }}
                    >
                      <div>
                        <label style={labelStyle}>Card Number</label>
                        <input
                          style={cardInputStyles}
                          type="number"
                          name="number"
                          placeholder="**** **** **** ****"
                          value={state.number}
                          onChange={handleInputChangeNumber}
                          onFocus={handleInputFocusNumber}
                        />
                      </div>
                      <Box sx={{ display: "flex", gap: "8px" }}>
                        <div>
                          <label style={labelStyle}>CVC</label>
                          <input
                            style={cardInputStyles}
                            type="number"
                            name="cvc"
                            placeholder="***"
                            value={state.cvc}
                            onChange={handleInputChangeNumber}
                            onFocus={handleInputFocusNumber}
                          />
                        </div>
                        <div>
                          <label style={labelStyle}>Expiry Date (MM/YY)</label>
                          <input
                            style={cardInputStyles}
                            type="text"
                            name="expiry"
                            placeholder="**/**"
                            value={state.expiry}
                            onChange={handleInputChangeNumber}
                            onFocus={handleInputFocusNumber}
                          />
                        </div>
                      </Box>
                      <div>
                        <label style={labelStyle}>Card Holder Name</label>
                        <input
                          style={cardInputStyles}
                          type="text"
                          name="name"
                          placeholder="Enter Your Name"
                          value={state.name}
                          onChange={handleInputChangeNumber}
                          onFocus={handleInputFocusNumber}
                        />
                      </div>
                      <ButtonComp
                        label={"Add Card"}
                        click={handleRegisterCard}
                      />
                    </form>
                  </Box>
                </TabPanel>
              )}
              {value === "2" && (
                <TabPanel value="2">
                  <Typography>e-wallet</Typography>
                </TabPanel>
              )}
            </Box>
          </TabContext>
        </Box>
      </Dialog>
    </>
  );
};

export default AddPaymentMethod;
