import React, { useEffect, useMemo, useState } from "react";
import Layout from "../../../components/globalComponents/Layout/Layout";
import ImageComp from "../../../components/globalComponents/ImageComp";
import { Box, Button, Tab, Typography } from "@mui/material";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import theme from "../../../theme";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Posts from "../../../components/Social/posts/Posts";
import Discover from "../../../components/globalComponents/discover/Discover";
import useGetAPI from "../../../features/hooks/useGetAPI";
import ForumIcon from "@mui/icons-material/Forum";
import { useParams } from "react-router-dom";
import useFetch from "../../../features/hooks/useFetch";
import { useDispatch, useSelector } from "react-redux";
import {
  setFollowing,
  setpendingAndFollowers,
} from "../../../features/slice/followSlice";
import UserComments from "../../../components/Social/Modals/comment/UserComments";

const OtherUser = () => {
  const [user, setUser] = useState(null);
  const [value, setValue] = useState("1");
  const [isFollower, setIsFollower] = useState(false);
  const params = useParams();
  const { getData, getRes } = useGetAPI();
  const { fetchData, postData, deleteData } = useFetch();
  const { following, pendingAndFollowers } = useSelector(
    (state) => state.follow
  );
  const dispatch = useDispatch();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const getUserPosts = async () => {
    await getData(`/api/user/post/user-posts/${params.email}`);
  };

  const getSpecificUser = async () => {
    await getData(`/api/user/get-user/${params.email}`, (data) => {
      setUser(data?.data);
    });
  };
  let allPosts = useMemo(() => {
    if (getRes && getRes?.data) {
      return getRes.data;
    }
  }, [getRes]);
  const handleMessageRequest = () => {
    console.log("message button clicked");
  };

  const checkFollower = () => {
    const follower = pendingAndFollowers?.find(
      (follower) => follower?.followingEmail === params?.email
    );

    if (follower) {
      if (follower.status === "accepted") {
        setIsFollower(true);
      } else if (follower.status === "pending") {
        setIsFollower("pending");
      }
    } else {
      setIsFollower(false);
    }
  };

  const getAllFollowers = async () => {
    await fetchData(
      "/api/user/connection/getall",
      setpendingAndFollowers,
      (res) => {
        // dispatch(setpendingAndFollowers(res));
      }
    );
  };

  const handleFollowRequest = () => {
    postData(
      "/api/user/connection",
      { followingEmail: user?.email },
      undefined,
      undefined,
      undefined,
      getAllFollowers
    );
  };

  const handleUnFollowRequest = () => {
    deleteData(`/api/user/connection/following/${user?.email}`, () => {
      getAllFollowers();
    });
  };

  useEffect(() => {
    const handlePromises = async () => {
      await getAllFollowers();
      await getUserPosts();
      await getSpecificUser();
    };
    handlePromises();
  }, []);

  useEffect(() => {
    checkFollower();
  }, [pendingAndFollowers]);

  const buttonStyles = {
    display: "flex",
    padding: "8px 12px",
    alignItems: "center",
    gap: "8px",
    background: theme.palette.primary.main,
    borderRadius: "30px",
    height: "38px",
    width: "max-content",
    color: "white",
  };

  const buttonHover = {
    color: theme.palette.primary.main,
    background: "white",
    border: `1px solid ${theme.palette.primary.main}`,
  };
  const [posts, setPosts] = useState([]);
  const handleCommentUpdate = (post) => {
    // console.log(post, "post");
    // setPosts(
    //   getRes.map((p) => {
    //     // console.log(p);
    //     return p.postID === post.postID ? post : p;
    //   })
    // );
  };

  return (
    <>
      <UserComments updComments={handleCommentUpdate} />
      <Box
        sx={{
          height: "360px",
          backgroundImage: `url(${user?.coverPic})`,
          backgroundSize: "cover",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      ></Box>
      <Layout title={user?.firstName + " " + user?.lastName + " | SMAC"}>
        <Box>
          <Box
            sx={{
              display: "flex",
              "@media (max-width:700px)": {
                flexWrap: "wrap",
              },
            }}
          >
            <Box
              sx={{
                position: "relative",
                bottom: "50px",
                "@media (max-width:700px)": {
                  height: "110px",
                  padding: "0 10px",
                },
              }}
            >
              <ImageComp
                src={
                  user?.profilePic ? user.profilePic : "assets/photos/profile"
                }
                alt={"User Profile"}
                sx={{
                  borderRadius: "50%",
                  height: "180px",
                  width: "180px",
                  backgroundSize: "cover",
                }}
              />
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                padding: "30px",
                flexWrap: "wrap",
                gap: 10,
              }}
            >
              <Box
                sx={{
                  maxWidth: "610px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "25px",
                }}
              >
                <Box>
                  <Typography variant="h2" sx={{ wordBreak: "break-all" }}>
                    {user?.firstName.substring(0, 10) +
                      " " +
                      user?.lastName?.substring(0, 10)}
                  </Typography>
                  {/* <Typography variant="h4Black">{user?.email}</Typography> */}
                </Box>
                {user?.bio ? (
                  <Typography variant="h5Black" sx={{ wordBreak: "break-all" }}>
                    {user.bio}
                  </Typography>
                ) : (
                  "No Bio Added"
                )}
                <Box sx={{ display: "flex", gap: "25px", flexWrap: "wrap" }}>
                  Born:{" "}
                  {user?.dob
                    ? new Date(user.dob).toLocaleDateString("en-US", {
                        day: "numeric",
                        month: "long",
                        year: "numeric",
                      })
                    : "No Birth Date Added"}
                  <LocationOnOutlinedIcon
                    sx={{
                      color: theme.palette.primary.main,
                    }}
                  />
                  {user?.address ? (
                    <Box sx={{ display: "flex", gap: "8px" }}>
                      <Typography sx={{ wordBreak: "break-all" }}>
                        {user.address}
                      </Typography>
                    </Box>
                  ) : (
                    "No Location Added"
                  )}
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: "25px" }}>
                {isFollower === true ? (
                  <Button
                    sx={{
                      ...buttonStyles,
                      "&:hover": { ...buttonHover },
                    }}
                    onClick={handleUnFollowRequest}
                  >
                    <Typography>Unfollow</Typography>
                  </Button>
                ) : isFollower === false ? (
                  <Button
                    sx={{
                      ...buttonStyles,
                      "&:hover": { ...buttonHover },
                    }}
                    onClick={handleFollowRequest}
                  >
                    <Typography>Follow</Typography>
                  </Button>
                ) : isFollower === "pending" ? (
                  <Button
                    sx={{
                      ...buttonStyles,
                      "&:hover": { ...buttonHover },
                    }}
                    onClick={handleUnFollowRequest}
                  >
                    <Typography>Pending</Typography>
                  </Button>
                ) : null}
                <Button
                  sx={{
                    ...buttonStyles,
                    "&:hover": { ...buttonHover },
                  }}
                  onClick={handleMessageRequest}
                >
                  <ForumIcon />
                  Send Message
                </Button>
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: "100%" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleChange} centered>
                  <Tab
                    label={
                      <Typography variant="h3" sx={{ width: "200px" }}>
                        Posts
                      </Typography>
                    }
                    value="1"
                  />
                  <Tab
                    label={
                      <Typography variant="button20" sx={{ width: "200px" }}>
                        Products
                      </Typography>
                    }
                    value="2"
                  />
                  <Tab
                    label={
                      <Typography variant="button20" sx={{ width: "200px" }}>
                        Courses
                      </Typography>
                    }
                    value="3"
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Box sx={{ padding: "40px 0px" }}>
                  <Posts myposts={allPosts ? allPosts : false} />
                </Box>
              </TabPanel>
              <TabPanel value="2">
                <Discover />
              </TabPanel>
              <TabPanel value="3">Courses</TabPanel>
            </TabContext>
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export default OtherUser;
