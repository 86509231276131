// src/EmailVerification.js
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import EmailSuccessful from "./EmailSuccessful";
import EmailErrorMessage from "./EmailErrorMessage";
const EmailVerification = () => {
  const location = useLocation();
  const jwt = new URLSearchParams(location.search).get("jwt");
  const [message, setMessage] = useState("Verifying...");
  const navigate = useNavigate();
  const [emailVerification, setEmailVerfication] = useState(false);
  useEffect(() => {
    // Replace with your backend endpoint
    console.log("jwt in verify email", jwt);

    fetch(`/api/auth/user/verify-email?jwt=${jwt}`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data, "check data");

        if (data?.status === 200) {
          setMessage("Email verified successfully!");
          setEmailVerfication(true);
          // setTimeout(() => {
          //   navigate("/");
          // }, 3000);
        } else {
          setMessage("Email verification failed. Invalid or expired token.");
          setEmailVerfication(false);
        }
      })
      .catch((error) => {
        setMessage("An error occurred. Please try again later.");
        setEmailVerfication(false);
        // console.error("Error verifying email:", error);
      });
  }, [jwt]);

  return (
    <div>
      {emailVerification ? (
        <EmailSuccessful  message={message} />
      ) : (
        <EmailErrorMessage ErrorMessage={message} />
      )}
      {/* <p>{message}</p> */}
    </div>
  );
};

export default EmailVerification;
