import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import { Typography } from "@mui/material";
import Layout from "./Layout/Layout";
import { navlinks } from "../data";
import ProfileDropdown from "../../pages/user/ProfileDropdown";
import SearchInput from "./global_inputs/SearchInput";
import SideNav from "./Modals/SideNav/SideNav";
import ImageComp from "./ImageComp";
import useFetch from "../../features/hooks/useFetch";

import { useDispatch } from "react-redux";
import {
  setUsers,
  setPosts,
  setProducts,
  setCourses,
  setSearchValue,
} from "../../features/slice/globalSearchSlice.js";
import NotificationsDrawer from "./NotificationsDrawer.jsx";
export default function Header() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = useState(null);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth <= 1000);
  const { fetchData } = useFetch();
  const handleGlobalSearch = (event) => {
    event.preventDefault();
    try {
      fetchData(
        `/api/search?searchQuery=${event.target.value}`,
        undefined,
        (res) => {
          if (res) {
            dispatch(setSearchValue({ data: event.target.value }));
            dispatch(setUsers({ data: res?.data?.users }));
            dispatch(setPosts({ data: res?.data?.posts }));
            dispatch(setProducts({ data: res?.data?.products }));
            dispatch(setCourses({ data: res?.data?.courses }));
            dispatch(setCourses({ data: res?.data?.courses }));
            navigate(`/search?searchQuery=${event.target.value}`);
          }
        }
      );

      // fetchData()
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth <= 1000);
    };

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      id="primary-search-account-menu"
      keepMounted
      transformOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      open={Boolean(anchorEl)}
      onClose={handleMenuClose}
      sx={{
        padding: "0px !important",
        margin: "0px",
        marginTop: "80px",
      }}
    >
      <ProfileDropdown onClose={handleMenuClose} />
    </Menu>
  );

  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      id="primary-search-account-menu-mobile"
      keepMounted
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={Boolean(mobileMoreAnchorEl)}
      onClose={handleMobileMenuClose}
    ></Menu>
  );

  return (
    <Box
      sx={{
        flexGrow: 1,
        background: "white",
        height: "97px",
        position: "sticky",
        top: "0",
        left: "0",
        right: "0",
        zIndex: "1000",
      }}
    >
      <Layout extra={{ alignItems: "center" }}>
        <AppBar
          position="static"
          sx={{
            background: "white",
            boxShadow: "0",
          }}
        >
          <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
            {isLargeScreen && <SideNav />}
            <Box
              sx={{
                width: "100%",
                maxWidth: "120px",
                minWidth: "90px",
              }}
              component={Link}
              to="/"
            >
              <ImageComp
                src={"assets/photos/logo.png"}
                alt={"smac logo"}
                styles={{ width: "100%" }}
              ></ImageComp>
            </Box>
            <SearchInput display={true} onChange={handleGlobalSearch} />
            <Box
              sx={{
                width: "100%",
                maxWidth: "750px",
                display: { sm: "none", xs: "none", md: "flex" },
                justifyContent: "space-between",
              }}
            >
              {navlinks.map((item) => (
                <IconButton
                  key={item.id}
                  size="small"
                  color="inherit"
                  component={Link}
                  to={item.url || "/"}
                  sx={{
                    "&:hover": {
                      color: "#14B8A6",
                      // borderBottom: "1px solid #14B8A6",
                      background: "transparent",
                      // fill: theme.palette.primary.main,
                    },
                    display: "flex",
                    flexDirection: "column",
                    borderRadius: "0px",
                    gap: "11px",
                    fontSize: "16px !important",
                    borderBottom:
                      location.pathname === item.url
                        ? "1px solid #14B8A6"
                        : "1px solid transparent",
                    color:
                      location.pathname === item.url ? "#14B8A6" : "inherit",
                    "&:focus": {
                      color: "#14B8A6",
                      borderBottom: "1px solid #14B8A6",
                      background: "transparent",
                    },
                  }}
                >
                  <>{item.linkIcon}</>

                  <Typography variant="body1">{item.linkName}</Typography>
                </IconButton>
              ))}
              <NotificationsDrawer />
              <IconButton
                size="small"
                edge="end"
                aria-label="account of current user"
                aria-controls="primary-search-account-menu"
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "flex",
                    lg: "flex",
                    xl: "flex",
                  },
                  flexDirection: "column",
                  "&:hover": {
                    color: "#14B8A6",
                    // borderBottom: "1px solid #14B8A6",
                    background: "transparent",
                    // fill: theme.palette.primary.main,
                  },

                  borderRadius: "0px",
                  gap: "11px",
                  fontSize: "16px !important",
                }}
              >
                <Box>
                  <Box>
                    <AccountCircle />
                  </Box>
                  <Box>
                    <Typography
                      variant="body2"
                      sx={{ fontSize: "16px", marginTop: "2px" }}
                    >
                      Me
                    </Typography>
                  </Box>
                </Box>
              </IconButton>
            </Box>

            {renderMobileMenu}
            {renderMenu}
          </Toolbar>
        </AppBar>
      </Layout>
    </Box>
  );
}
