import React, { useEffect, useMemo, useState } from "react";
import Layout from "./../../components/globalComponents/Layout/Layout";
import ImageComp from "../../components/globalComponents/ImageComp";
import { Box, Button, Tab, Typography } from "@mui/material";
import CakeOutlinedIcon from "@mui/icons-material/CakeOutlined";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
// import AddRoundedIcon from "@mui/icons-material/AddRounded";
import EditRoundedIcon from "@mui/icons-material/EditRounded";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import theme from "../../theme";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import Posts from "../../components/Social/posts/Posts";
import UploadPost from "../../components/Social/Modals/UploadPost/UploadPost";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import useGetAPI from "../../features/hooks/useGetAPI";
import useFetch from "../../features/hooks/useFetch";
import { useImagePreview } from "../../features/hooks/useImagePreview";
import DoneRoundedIcon from "@mui/icons-material/DoneRounded";
import { setUser } from "../../features/slice/userSlice";
import AddStory from "../../components/Social/Modals/Story/AddStory/AddStory";
import { setMultiplePost } from "../../features/slice/postSlice";
import UserComments from "../../components/Social/Modals/comment/UserComments";
import { toast } from "react-toastify";
import ProfilePicture from "../../components/globalComponents/ProfilePicture";
import UserCourses from "../../components/DashBoard/UserDashboard/UserCourses";
import ProductsTab from "./tabComponents/ProductsTab";

const UserProfile = () => {
  // get user from store
  const { user } = useSelector((state) => state.user);
  const { multiplePosts } = useSelector((state) => state.post);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("1");
  const [profilePic, setProfilePic] = useState(null);
  const [coverPic, setCoverPic] = useState(null);
  const [flag, setFlag] = useState(false);
  const [coverFlag, setCoverFlag] = useState(false);
  const [newPost, setNewPost] = useState(false);
  const [del, setDel] = useState(null);
  const [posts, setPosts] = useState([]);

  const dispatch = useDispatch();
  var profilePicPreview = useImagePreview(profilePic);
  var CoverPicPreview = useImagePreview(coverPic);

  const { patchData, loading } = useFetch();
  const navigate = useNavigate();
  const { getData } = useGetAPI();
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  //get posts of only the logged in user
  const getUserPosts = async () => {
    await getData("/api/user/post/my-posts", (res) => {
      // console.log(res, "userPosts");
      dispatch(setMultiplePost(res));
    });
  };
  // eslint-disable-next-line
  useEffect(() => {
    getUserPosts();
  }, [newPost, del]);
  // eslint-disable-next-line
  let allPosts = useMemo(() => {
    if (multiplePosts) {
      return multiplePosts;
    }
  }, [multiplePosts]);
  //to change profile pic
  const handleSelectProfilePic = (e) => {
    let selectedImage = e.target.files[0];
    // console.log(selectedImage, "Profile");
    // console.log(selectedImage.type.startsWith(
    //   "image/jpeg"
    // ), "Profile test");
    const imageTypes = [
      "image/webp",
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/svg",
    ];
    const isValidImageType = imageTypes.some((type) =>
      selectedImage.type.startsWith(type)
    );
    if (isValidImageType) {
      setProfilePic(selectedImage);
      setFlag(true);
    } else {
      toast.error(
        "Invalid image type.Please select only jpg,png,svg and jpeg."
      );
    }
  };

  const profileChange = async () => {
    let formData = new FormData();

    formData.append("profilePic", profilePic);
    await patchData(
      "/api/user/profilepic",
      formData,
      setUser,
      (res) => {
        console.log(res, "response for the Profile Photo");
        setFlag(false);
        setProfilePic(null);
        if (profilePic === null) {
        }
      },
      true
    );
  };

  //to change cover photo validation and conversion
  const handleSelectCoverPic = (e) => {
    let selectedImage = e.target.files[0];
    const imageTypes = [
      "image/webp",
      "image/jpeg",
      "image/jpg",
      "image/png",
      "image/svg",
    ];
    const isValidImageType = imageTypes.some((type) =>
      selectedImage.type.startsWith(type)
    );
    if (isValidImageType) {
      setCoverPic(selectedImage);
      setCoverFlag(true);
    } else {
      toast.error(
        "Invalid image type.Please select only jpg,png,svg and jpeg."
      );
    }
  };
  //change cover photo
  const coverChange = async () => {
    let formData = new FormData();
    formData.append("coverPic", coverPic);
    await patchData(
      "/api/user/coverPic",
      formData,
      setUser,
      (res) => {
        setCoverFlag(false);
        setCoverPic(null);
      },
      true
    );
  };
  //add story handler
  // const handleClickOpen = () => {
  //   setOpen(!open);
  // };

  const buttonStyles = {
    display: "flex",
    padding: "8px 12px",
    alignItems: "center",
    gap: "8px",
    background: theme.palette.primary.main,
    borderRadius: "30px",
    height: "38px",
    width: "max-content",
    color: "white",
  };
  const buttonHover = {
    color: theme.palette.primary.main,
    background: "white",
    border: `1px solid ${theme.palette.primary.main}`,
  };
  const handleCommentUpdate = (post) => {
    setPosts(
      multiplePosts.map((p) => {
        // console.log(p);
        return p.postID === post.postID ? post : p;
      })
    );
  };

  return (
    <>
      {/* //models for comment and add story */}
      <UserComments updComments={handleCommentUpdate} />
      <AddStory open={open} setOpen={setOpen} />
      <Box
        sx={{
          height: "360px",
          backgroundImage: `url(${
            CoverPicPreview
              ? CoverPicPreview
              : user?.coverPic
              ? user?.coverPic
              : "assets/photos/profile-cover.png"
          })`,
          backgroundSize: "cover",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {coverFlag ? (
          <>
            <Button
              sx={{
                background: "#333",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
                padding: "5px 10px",
                borderRadius: "30px",
              }}
              disabled={loading}
              component="label"
              onClick={coverChange}
            >
              <DoneRoundedIcon sx={{ color: "white" }} />
              <Typography variant="editPicButton">Save changes</Typography>
            </Button>
          </>
        ) : (
          <>
            <Button
              sx={{
                background: "#333",
                display: "inline-flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "8px",
                padding: "5px 10px",
                borderRadius: "30px",
              }}
              component="label"
            >
              <input
                type="file"
                name="file"
                accept="image/*"
                id="cover-upload"
                hidden
                onChange={handleSelectCoverPic}
              />
              <CameraAltRoundedIcon sx={{ color: "white" }} />
              <Typography variant="editPicButton">Edit cover photo</Typography>
            </Button>
          </>
        )}
      </Box>
      {/* <ImageComp src={"assets/photos/story.png"} /> */}
      <Layout title={"My - Space | SMAC"}>
        <Box>
          <Box
            sx={{
              display: "flex",
              "@media (max-width:700px)": {
                flexWrap: "wrap",
              },
            }}
          >
            <Box
              sx={{
                position: "relative",
                bottom: "60px",
                "@media (max-width:700px)": {
                  height: "110px",
                  padding: "0 10px",
                },
              }}
            >
              <ProfilePicture
                src={profilePicPreview ? profilePicPreview : user?.profilePic}
                firstName={user?.firstName}
                sx={{
                  height: "180px",
                  width: "180px",
                }}
                innerBox={{
                  height: "144px",
                  width: "144px",
                }}
              />
              {flag ? (
                <>
                  <Button
                    sx={{
                      position: "relative",
                      left: "130px",
                      bottom: "50px",
                      background: "#333",
                      minWidth: "37px !important",
                      maxWidth: "37px !important",
                      height: "37px",
                      borderRadisus: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    accept="image/*"
                    component="label"
                    onClick={profileChange}
                    disabled={loading}
                  >
                    <DoneRoundedIcon />
                  </Button>
                </>
              ) : (
                <>
                  <Button
                    sx={{
                      position: "relative",
                      left: "130px",
                      bottom: "50px",
                      background: "#333",
                      minWidth: "37px !important",
                      maxWidth: "37px !important",
                      height: "37px",

                      borderRadisus: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                    component="label"
                  >
                    <input
                      type="file"
                      name="file"
                      accept="image/*"
                      id="profile-upload"
                      hidden
                      onChange={handleSelectProfilePic}
                    />
                    <CameraAltRoundedIcon sx={{ color: "white" }} />
                  </Button>
                </>
              )}
            </Box>
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                padding: "30px",
                flexWrap: "wrap",
                gap: 10,
              }}
            >
              <Box
                sx={{
                  maxWidth: "610px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "25px",
                }}
              >
                <Box>
                  <Typography variant="h2" sx={{ wordBreak: "break-all" }}>
                    {user?.firstName + user?.lastName}
                  </Typography>
                  {/* <Typography variant="h4Black">{user?.email}</Typography> */}
                </Box>
                <Typography variant="h5Black" sx={{ wordBreak: "break-all" }}>
                  {user.bio ? user.bio : "No Bio Added"}
                </Typography>
                <Box sx={{ display: "flex", gap: "25px", flexWrap: "wrap" }}>
                  <Box
                    sx={{
                      display: "flex",
                      gap: "8px",
                    }}
                  >
                    <CakeOutlinedIcon
                      sx={{
                        color: theme.palette.primary.main,
                      }}
                    />
                    <Typography>
                      Born:{" "}
                      {user.dob
                        ? new Date(user.dob).toLocaleDateString("en-US", {
                            day: "numeric",
                            month: "long",
                            year: "numeric",
                          })
                        : "No Birth Date Added"}
                    </Typography>
                  </Box>
                  <Box sx={{ display: "flex", gap: "8px" }}>
                    <LocationOnOutlinedIcon
                      sx={{
                        color: theme.palette.primary.main,
                      }}
                    />
                    <Typography sx={{ wordBreak: "break-all" }}>
                      {user.address ? user.address : "No Location Added"}
                    </Typography>
                  </Box>
                  <Box></Box>
                </Box>
              </Box>
              <Box sx={{ display: "flex", gap: "25px" }}>
                {/* <Button
                  sx={{ ...buttonStyles, "&:hover": { ...buttonHover } }}
                  onClick={handleClickOpen}
                >
                  <AddRoundedIcon />
                  Add to story
                </Button> */}

                {/* edit profile / settings button */}
                <Button
                  sx={{
                    ...buttonStyles,
                    "&:hover": { ...buttonHover },
                  }}
                  onClick={() => {
                    navigate("/update");
                  }}
                >
                  <EditRoundedIcon />
                  Edit profile
                </Button>
              </Box>
            </Box>
          </Box>

          {/* This is for the Post Tabs  */}

          <Box sx={{ width: "100%" }}>
            <TabContext value={value}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList onChange={handleChange} centered>
                  <Tab
                    label={
                      <Typography variant="h3" sx={{ width: "200px" }}>
                        Posts
                      </Typography>
                    }
                    value="1"
                  />
                  <Tab
                    label={
                      <Typography variant="button20" sx={{ width: "200px" }}>
                        Products
                      </Typography>
                    }
                    value="2"
                  />
                  <Tab
                    label={
                      <Typography variant="button20" sx={{ width: "200px" }}>
                        Courses
                      </Typography>
                    }
                    value="3"
                  />
                </TabList>
              </Box>
              <TabPanel value="1">
                <Box sx={{ padding: "40px 0px" }}>
                  <Posts
                    delCb={setDel}
                    myposts={allPosts ? allPosts : false}
                    key={0}
                  />
                </Box>
              </TabPanel>
              <TabPanel value="2">
                <Box sx={{ padding: "40px 0px" }}>
                  {" "}
                  <ProductsTab />
                </Box>
              </TabPanel>
              <TabPanel value="3">
                <Box sx={{ padding: "40px 0px" }}>Courses</Box>
              </TabPanel>
            </TabContext>
          </Box>
        </Box>
        <UploadPost
          cb={setNewPost}
          style={{
            position: "sticky",
            left: "92.5%",
            bottom: "6.7%",
            width: "105px",
          }}
        />
      </Layout>
    </>
  );
};

export default UserProfile;
