export const countryList = [
  {
    id: "1",
    countryName: "Pakistan",
  },
  {
    id: "2",
    countryName: "China",
  },
  {
    id: "3",
    countryName: "Japan",
  },
  {
    id: "4",
    countryName: "India",
  },
  {
    id: "5",
    countryName: "Afghanistan",
  },
];
export const languageList = [
  {
    id: 1,
    languageName: "English (US)",
  },
  {
    id: 2,
    languageName: "Mandrin",
  },
  {
    id: 3,
    languageName: "Japanees",
  },
  {
    id: 4,
    languageName: "Hindi",
  },
  {
    id: 5,
    languageName: "Pashtu",
  },
];
export const genderList = [
  {
    id: 1,
    genderName: "Male",
  },
  {
    id: 2,
    genderName: "Female",
  },
  {
    id: 3,
    genderName: "Other",
  },
];

export const share = [
  {
    id: 1,
    src: "assets/photos/CommentPic.png",
    name: "Robert Fin",
    userName: "Robert Fin",
  },
  {
    id: 2,
    src: "assets/photos/CommentPic.png",
    name: "Robert Fin",
    userName: "Robert Fin",
  },
  {
    id: 3,
    src: "assets/photos/CommentPic.png",
    name: "Robert Fin",
    userName: "Robert Fin",
  }
];

