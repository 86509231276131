import * as React from "react";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import {
  Box,
  Divider,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@mui/material";
import useFetch from "../../../../features/hooks/useFetch";
import { useEffect } from "react";
import useGetAPI from "../../../../features/hooks/useGetAPI";
import moment from "moment";
import DashBoardSearchInputField from "../../../../components/DashBoard/DashBoardSearchInputField/DashBoardSearchInputField";
import { useDispatch, useSelector } from "react-redux";
import {
  setDashboardAllProducts,
  setDashboardProducts,
} from "../../../../features/slice/DashBoardProductsSlice";
import ViewProduct from "../../../../components/Market/Modals/ViewProduct/ViewProduct";
import { setIsViewProductOpen } from "../../../../features/slice/Market/viewProductSlice";

export default function DashboardMarket() {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [marketData, setMarketData] = React.useState([]);
  const { fetchData } = useFetch();
  const { getData } = useGetAPI();
  const { deleteData } = useFetch();
  const [showMarketData, setShowMarketData] = React.useState(null);
  const dispatch = useDispatch();
  const [open, setOpen] = React.useState(false);
  const [selectedCourseId, setSelectedCourseId] = React.useState(null);
  // const openModal = () => setOpen(true);
  const { isViewProductOpen } = useSelector((state) => state.viewProduct);
  const openModal = (dataCourse) => {
    console.log("Clicked");
    setShowMarketData(dataCourse);
    setOpen(true);
    dispatch(setIsViewProductOpen({ open: !isViewProductOpen }));
  };

  // const [prductsOfUser,setProductsOfUser]=React.useState(null)
  // geting User from the Redux

  const user = useSelector(
    (state) => state.DashBoardProductsSlice.dashBoarduser
  );

  // getting Products from the redux..

  const FilterProducts = useSelector(
    (state) => state.DashBoardProductsSlice.dashBoardproducts
  );
  // console.log(FilterProducts, " filter products in Market");
  const handleDeleteIconClick = (event, productId) => {
    // console.log(courseId,"ccc");
    setAnchorEl(event.currentTarget);
    setSelectedCourseId(productId.productId);
  };
  // delete handler get product id from event  and send to server for deleting it
  const handleDeleteOptionClick = (productId) => {
    setAnchorEl(null);
    deleteData(`/api/admin/product/${productId}`, (res) => {
      //filter  data after deletion and update marketData
      const afterDelete = marketData.filter((valueData) => {
        return valueData.productId !== productId;
      });
      setMarketData(afterDelete);
    });
  };

  const handleCloseMenu = () => {
    setAnchorEl(null);
  };
  useEffect(() => {
    //hit admin  api /api/admin/product and set  it to dashboardProduct
    getData("/api/admin/product", (res) => {
      // console.log(res, "response in the Market");
      setMarketData(res.data);
      //dispatch  this data to DashBoardReducer
      dispatch(setDashboardAllProducts(res.data));
      //check user in redux uset that data (user.email) to get all products
      if (user?.email) {
        fetchData("/api/admin/product/" + user.email, undefined, (res) => {
          //set products in redux
          dispatch(setDashboardProducts(res.data));
        });
      }
    });
  }, []);

  return (
    <>
      <Box
        sx={{
          backgroundColor: "white",
          borderRadius: "20px",
          padding: "15px",
        }}
      >
        <Typography
          variant="h2"
          noWrap
          component={"span"}
          sx={{
            margin: "10px 0",
          }}
        >
          Market
        </Typography>
        <Divider
          sx={{
            width: "100%",
          }}
        />
        <Box
          sx={{
            margin: "10px 0px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <DashBoardSearchInputField
            url={"api/admin/product/"}
            cb={setMarketData}
          />
        </Box>
        {/* here i'm getting the value from redux */}
        <Box>
          {" "}
          {user && user ? (
            <>
              <Typography
                component={"span"}
                variant="h2"
                sx={{
                  padding: "10px 0px",
                }}
              >
                User personal details
              </Typography>
              <TableContainer>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableBody>
                    <TableRow>
                      <TableCell>
                        <img
                          style={{
                            width: "100%",
                            maxWidth: "100px",
                            height: "100px",
                            borderRadius: "50%",
                            objectFit: "cover",
                          }}
                          src={
                            user && user.profilePic
                              ? user.profilePic
                              : "https://upload.wikimedia.org/wikipedia/commons/thumb/6/65/No-Image-Placeholder.svg/1665px-No-Image-Placeholder.svg.png"
                          }
                          alt="UserImage"
                        />
                      </TableCell>
                      <TableCell>
                        <Typography
                          component={"span"}
                          variant="h5"
                          sx={{ marginBottom: "10px", display: "block" }}
                        >
                          Name
                        </Typography>
                        {user ? user.firstName + " " + user.lastName : ""}
                      </TableCell>

                      <TableCell>
                        <Typography
                          component={"span"}
                          variant="h5"
                          sx={{ marginBottom: "10px", display: "block" }}
                        >
                          Email
                        </Typography>
                        {user ? user.email : ""}
                      </TableCell>
                      <TableCell>
                        <Typography
                          component={"span"}
                          variant="h5"
                          sx={{ marginBottom: "10px", display: "block" }}
                        >
                          Status
                        </Typography>
                        {user ? (user.isBlocked ? "Unblock" : "Block") : ""}
                      </TableCell>
                      {/* this is clear icon */}
                      {/* <TableCell>
                        <ButtonComp
                          customStyles={{ width: "2px" }}
                          icon={<ClearIcon sx={{ marginRight: "65px" }} />}
                        />
                      </TableCell> */}
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </>
          ) : (
            ""
          )}
        </Box>
        {/* end of redux */}
        <Typography
          component={"span"}
          variant="h2"
          sx={{
            padding: "20px 0px",
          }}
        >
          User Product details
        </Typography>

        {FilterProducts && FilterProducts.length > 0 ? (
          <Grid container spacing={"5px"} item>
            {FilterProducts.map((FilterProducts) => {
              return (
                <Grid
                  item
                  onClick={() => {
                    openModal(FilterProducts);
                  }}
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={4}
                  key={FilterProducts.courseId}
                  marginY={2}
                  style={{
                    width: "100% !important",
                  }}
                >
                  <Card
                    sx={{
                      position: "relative",
                      maxWidth: 345,
                      borderRadius: "15px",
                      paddingBottom: "25px",
                      minHeight: "550px",
                    }}
                  >
                    <img
                      src={FilterProducts.images[0]}
                      style={{
                        width: "100%",
                        borderRadius: "15px 15px 0 0",
                        objectFit: "cover",
                        height: "339px",
                      }}
                      alt="Market"
                    />
                    <CardContent sx={{ padding: "20px" }}>
                      <Typography
                        component={"span"}
                        gutterBottom
                        sx={{ display: "block", wordBreak: "break-all" }}
                        variant="h3"
                      >
                        {FilterProducts.title.length > 25
                          ? FilterProducts.title.substring(0, 25) + "...."
                          : FilterProducts.title}
                      </Typography>
                      <Typography
                        sx={{
                          display: "flex",
                          height: "50px",
                          overflow: "hidden",
                          wordBreak: "break-all",
                        }}
                      >
                        {FilterProducts.description.length > 45
                          ? FilterProducts.description.substring(0, 45) +
                            "....."
                          : FilterProducts.description}
                      </Typography>
                      <Typography
                        component={"span"}
                        sx={{
                          display: "flex",
                          position: "absolute",
                          bottom: "15px",
                        }}
                      >
                        {moment
                          .utc(FilterProducts.createdAt)
                          .local()
                          .startOf("seconds")
                          .fromNow()}
                      </Typography>
                    </CardContent>
                    <IconButton
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        margin: "5px 5px 0px 0px",
                        isolation: "isolate",
                        // mixBlendMode:"difference",
                        color: "white",
                        backgroundColor: "black",
                        filter: "invert(1)",
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDeleteIconClick(event, FilterProducts);
                      }}
                    >
                      <MoreVertIcon
                      // sx={{
                      //   position: "absolute",
                      //   top: 0,
                      //   right: 0,
                      //   margin: "5px 5px 0px 0px",
                      //   color: "white",
                      // }}
                      // onClick={(event) => {
                      //   event.stopPropagation();
                      //   handleDeleteIconClick(event, FilterProducts);
                      // }}
                      />
                    </IconButton>

                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenu}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem
                        onClick={(event) => {
                          event.stopPropagation();
                          handleDeleteOptionClick(selectedCourseId);
                        }}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        ) : FilterProducts && FilterProducts.length === 0 ? (
          <Typography component={"span"} sx={{ display: "block" }}>
            No Products of this User
          </Typography>
        ) : (
          <Grid container spacing={"5px"} item>
            {marketData?.map((DashMarket, index) => {
              return (
                <Grid
                  onClick={() => {
                    openModal(DashMarket);
                  }}
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={4}
                  xl={4}
                  key={DashMarket.productId}
                  marginY={2}
                  style={{
                    width: "100% !important",
                  }}
                >
                  <Card
                    key={index}
                    sx={{
                      position: "relative",
                      maxWidth: 345,
                      borderRadius: "15px",
                      // height: "523px",
                      minHeight: "550px",
                      paddingBottom: "25px",
                    }}
                  >
                    <img
                      src={DashMarket.images[0]}
                      style={{
                        width: "100%",
                        borderRadius: "15px 15px 0 0",
                        objectFit: "cover",
                        height: "339px",
                      }}
                      alt="Market"
                    />
                    <CardContent sx={{ padding: "20px" }}>
                      <Typography
                        gutterBottom
                        sx={{ display: "block", wordBreak: "break-all" }}
                        component={"span"}
                        variant="h3"
                      >
                        {DashMarket.title.length > 25
                          ? DashMarket.title.substring(0, 25) + "....."
                          : DashMarket.title}
                      </Typography>
                      <Typography
                        component={"span"}
                        sx={{
                          display: "flex",
                          height: "50px",
                          overflow: "hidden",
                          wordBreak: "break-all",
                        }}
                      >
                        {DashMarket.description.length > 45
                          ? DashMarket.description.substring(0, 45) + "....."
                          : DashMarket.description}
                      </Typography>
                      <Typography
                        component={"span"}
                        sx={{
                          display: "flex",
                          position: "absolute",
                          bottom: "15px",
                        }}
                      >
                        {moment
                          .utc(DashMarket.createdAt)
                          .local()
                          .startOf("seconds")
                          .fromNow()}
                      </Typography>
                    </CardContent>

                    <IconButton
                      sx={{
                        position: "absolute",
                        top: 0,
                        right: 0,
                        margin: "5px 5px 0px 0px",
                        isolation: "isolate",
                        // mixBlendMode:"difference",
                        color: "white",
                        backgroundColor: "black",
                        filter: "invert(1)",
                      }}
                      onClick={(event) => {
                        event.stopPropagation();
                        handleDeleteIconClick(event, DashMarket);
                      }}
                    >
                      <MoreVertIcon
                      // sx={{
                      //   position: "absolute",
                      //   top: 0,
                      //   right: 0,
                      //   margin: "5px 5px 0px 0px",
                      //   color: "white",
                      // }}
                      // onClick={(event) => {
                      //   event.stopPropagation();
                      //   handleDeleteIconClick(event, DashMarket);
                      // }}
                      />
                    </IconButton>

                    <Menu
                      anchorEl={anchorEl}
                      open={Boolean(anchorEl)}
                      onClose={handleCloseMenu}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                    >
                      <MenuItem
                        onClick={(event) => {
                          event.stopPropagation();
                          handleDeleteOptionClick(selectedCourseId);
                        }}
                      >
                        Delete
                      </MenuItem>
                    </Menu>
                  </Card>
                </Grid>
              );
            })}
          </Grid>
        )}
      </Box>
      <ViewProduct dataShow={showMarketData} open={open} />
    </>
  );
}
