import { Box, Divider, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";

import TuneIcon from "@mui/icons-material/Tune";
import CourseCard from "./Cards/CourseCard";
import useFetch from "../../../features/hooks/useFetch";
import Layout from "../../globalComponents/Layout/Layout";
import theme from "../../../theme";
// import { toast } from "react-toastify";
const PurchasedCourses = () => {
  const [courseList, setCourseList] = useState([]);
  const { fetchData } = useFetch();
  const getPurchasedCourses = () => {
    fetchData("/api/course/user/orders", undefined, (res) => {
      console.log(res.data, "res");
      setCourseList(res.data);
    });
  };
  useEffect(() => {
    getPurchasedCourses();
  }, []);
  return (
    <>
      <Layout styles={{ padding: "0" }}>
        <Box
          sx={{
            // maxWidth: "1252px",
            backgroundColor: "white",
            borderRadius: "20px",
            padding: "15px",
            height: "auto",
            width: "100%",
            [theme.breakpoints.down("md")]: {
              padding: "0px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "40px 20px 24px 20px",
              width: "100%",
            }}
          >
            <Typography variant="userDashboardHeading">
              Purchased Courses
            </Typography>
            <IconButton>
              <TuneIcon />
            </IconButton>
          </Box>
          <Divider />
          <Box
            sx={{
              padding: "40px",
              [theme.breakpoints.down("md")]: {
                padding: "16px",
              },
            }}
            component={"div"}
          >
            <Typography variant="postUserTypo">User courses details</Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "20px",
                mt: "12px",
              }}
            >
              {courseList?.map((course) => {
                return (
                  <CourseCard
                    key={course.courseId}
                    item={course}
                    name={"purchasedCourses"}
                    orderID={course.orderId}
                  />
                );
              })}
            </Box>
          </Box>
        </Box>
      </Layout>
    </>
  );
};

export default PurchasedCourses;
