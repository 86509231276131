import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scroller,
} from "react-scroll";
import Layout from "./Layout/Layout";

const styles = {
  fontFamily: "sans-serif",
  textAlign: "center",
};

const ScrollNavigation = () => {
  // Effect for registering and unregistering events
  useEffect(() => {
    const handleBegin = (...args) => {
      console.log("begin", args);
    };

    const handleEnd = (...args) => {
      console.log("end", args);
    };

    Events.scrollEvent.register("begin", handleBegin);
    Events.scrollEvent.register("end", handleEnd);

    return () => {
      Events.scrollEvent.remove("begin", handleBegin);
      Events.scrollEvent.remove("end", handleEnd);
    };
  }, []);

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  const scrollTo = () => {
    scroller.scrollTo("scroll-to-element", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  const scrollToWithContainer = async () => {
    await new Promise((resolve) => {
      const handleEnd = () => {
        resolve();
        Events.scrollEvent.remove("end", handleEnd);
      };

      Events.scrollEvent.register("end", handleEnd);

      scroller.scrollTo("scroll-container", {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    });

    scroller.scrollTo("scroll-container-second-element", {
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
      containerId: "scroll-container",
    });
  };

  return (
    <div style={{ display: "flex" }}>
      <ol
        style={{
          minWidth: "260px",
          display: "flex",
          flexDirection: "column",
          gap: "16px",
        }}
      >
        <li>
          {" "}
          <Link
            activeClass="active"
            to="firstInsideContainer"
            spy={true}
            smooth={true}
            duration={250}
            containerId="containerElement"
            style={{ display: "inline-block", cursor: "pointer" }}
            activeStyle={{ color: "red" }}
          >
            General Terms and conditions
          </Link>
        </li>
        <li>
          <Link
            activeClass="active"
            activeStyle={{ color: "red" }}
            to="secondInsideContainer"
            spy={true}
            smooth={true}
            duration={250}
            containerId="containerElement"
            style={{
              display: "inline-block",

              cursor: "pointer",
            }}
          >
            Second state
          </Link>
        </li>
      </ol>

      <Element
        name="test7"
        className="element"
        id="containerElement"
        style={{
          position: "relative",
          height: "800px",
          overflow: "scroll",
          // marginBottom: "100px",
        }}
      >
        <Element name="firstInsideContainer" style={{ marginBottom: "50px" }}>
          <Typography variant="button20">First Element</Typography>
          <p style={{ font: "20px", lineHeight: "36px", color: "#868686" }}>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply
            dummy text of the printing and typesetting industry. Lorem Ipsum has
            been the industry's standard dummy text ever since the 1500s, when
            an unknown printer took a galley of type and scrambled it to make a
            type specimen book. It has survived not only five centuries, but
            also the leap into electronic typesetting, remaining essentially
            unchanged. It was popularised in the 1960s with the release of
            Letraset sheets containing Lorem Ipsum passages, and more recently
            with desktop publishing software like Aldus PageMaker including
            versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the
            printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy text ever since the 1500s, when an unknown
            printer took a galley of type and scrambled it to make a type
            specimen book. It has survived not only five centuries, but also the
            leap into electronic typesetting, remaining essentially unchanged.
            It was popularised in the 1960s with the release of Letraset sheets
            containing Lorem Ipsum passages, and more recently with desktop
            publishing software like Aldus PageMaker including versions of Lorem
            Ipsum.
          </p>
        </Element>
        <Element name="secondInsideContainer" style={{ marginBottom: "50px" }}>
          <Typography variant="button20">Second Element</Typography>
          <p style={{ font: "20px", lineHeight: "36px", color: "#868686" }}>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s, when an unknown printer took a galley of type
            and scrambled it to make a type specimen book. It has survived not
            only five centuries, but also the leap into electronic typesetting,
            remaining essentially unchanged. It was popularised in the 1960s
            with the release of Letraset sheets containing Lorem Ipsum passages,
            and more recently with desktop publishing software like Aldus
            PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply
            dummy text of the printing and typesetting industry. Lorem Ipsum has
            been the industry's standard dummy text ever since the 1500s, when
            an unknown printer took a galley of type and scrambled it to make a
            type specimen book. It has survived not only five centuries, but
            also the leap into electronic typesetting, remaining essentially
            unchanged. It was popularised in the 1960s with the release of
            Letraset sheets containing Lorem Ipsum passages, and more recently
            with desktop publishing software like Aldus PageMaker including
            versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the
            printing and typesetting industry. Lorem Ipsum has been the
            industry's standard dummy text ever since the 1500s, when an unknown
            printer took a galley of type and scrambled it to make a type
            specimen book. It has survived not only five centuries, but also the
            leap into electronic typesetting, remaining essentially unchanged.
            It was popularised in the 1960s with the release of Letraset sheets
            containing Lorem Ipsum passages, and more recently with desktop
            publishing software like Aldus PageMaker including versions of Lorem
            Ipsum.
          </p>
        </Element>
      </Element>
    </div>
  );
};

export default ScrollNavigation;
