import { Box, Button, IconButton, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import theme from "../../../../../theme";
import { useImagePreview } from "../../../../../features/hooks/useImagePreview";
import ImageComp from "../../../../globalComponents/ImageComp";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch } from "react-redux";

import { toast } from "react-toastify";
import { setServiceImages } from "../../../../../features/slice/addServiceSlice";
import { useSelector } from "react-redux";

const UploadPictures = ({
  activeStep,
  setActiveStep,
  setCourseImages,
  courseImages,
}) => {
  // let { serviceImages } = useSelector((state) => state.addService);
  // serviceImages = JSON.parse(serviceImages ? serviceImages : "[]");
  // const [coursePics, setCoursePics] = useState(
  //   serviceImages?.length > 0 ? courseImages : null
  // );

  // useEffect(() => {
  //   if (coursePicPriviews.length > 0) {
  //     dispatch(setServiceImages({ service: JSON.stringify(coursePics) }));
  //   }
  // }, [coursePicPriviews]);

  const [checkLoading, setCheckLoading] = useState(true);
  const dispatch = useDispatch();
  const handlePicUpload = (e) => {
    e.preventDefault();
    const { files } = e.target;
    const imagesArray = Array.from(files);

    if (imagesArray.length > 10) {
      toast.error("The maximum limit of adding pictures is 10.");
    } else {
      const validImages = imagesArray.filter((file) =>
        file.type.startsWith("image/")
      );
      if (validImages.length !== imagesArray.length) {
        toast.error("Please select only images");
      }
      setCourseImages(validImages);
    }
  };

  // const handleDelete = (index) => {
  //   if (courseImages && Object.keys(courseImages).length) {
  //     const { [index]: _, ...newFilteredObject } = courseImages;
  //     setCourseImages({ ...newFilteredObject });
  //   }

  //   //  if (serviceImages) {
  //   //     let newFilteredArray = [];
  //   //     newFilteredArray = serviceImages.filter((item) => item.name !== e.name);
  //   //     dispatch(setServiceImages({ service: newFilteredArray }));
  //   //   }
  // };
  const handleDelete = (index) => {
    if (courseImages?.length > 0) {
      const updatedImages = courseImages.filter((_, i) => i !== index);
      setCourseImages(updatedImages);
    }
  };

  const handleNext = (e) => {
    // Optionally, dispatch the images to Redux before moving to the next step
    dispatch(setServiceImages({ service: courseImages }));
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };
  const handleBack = (e) => {
    setActiveStep((prevActiveStep) => {
      return prevActiveStep - 1;
    });
  };

  const checkLoadingFun = () => {
    if (Object.keys(courseImages).length === 0) {
      setCheckLoading(true);
    } else {
      setCheckLoading(false);
    }
  };
  useEffect(() => {
    // convertBlobToFile();
    checkLoadingFun();
  }, [courseImages]);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: "24px" }}>
      <Typography variant="h5">
        Upload and attach photos of your product, maximum 10 photos.
      </Typography>

      <Box>
        <Button
          sx={{
            background: "rgba(20, 184, 166, 0.02)",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
            gap: "8px",
            padding: "5px 10px",
            borderRadius: "16px",
            border: `1px dashed ${theme.palette.primary.main}`,
            width: "100%",
            height: "216px",
          }}
          component="label"
        >
          <input
            type="file"
            accept="image/*"
            hidden
            multiple
            onChange={handlePicUpload}
          />
          <Box
            sx={{
              width: "80px",
              height: "80px",
              background: `rgba(20, 184, 166,0.08)`,
              // opacity: "0.08",
              borderRadius: "50%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                width: "60px",
                height: "60px",
                // opacity: "0.16 !important",
                borderRadius: "50%",
                background: `rgba(20, 184, 166,0.16)`,
                //   background: `${theme.palette.primary.main}`,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <CloudUploadOutlinedIcon
                sx={{
                  color: `${theme.palette.primary.main}`,
                  width: "40px",
                  height: "40px",
                }}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Typography variant="uploadPictureBold">Click to upload</Typography>
            <Typography variant="h5" sx={{ ml: "3px" }}>
              or drag and drop photos
            </Typography>
          </Box>
        </Button>
      </Box>

      {courseImages?.length > 0 && (
        <Typography variant="h5">
          {courseImages?.length + " "}
          {`${courseImages?.length === 1 ? "Picture" : "Pictures"}`} to upload
        </Typography>
      )}
      {/* <Box sx={{ display: "flex", gap: "12px", flexWrap: "wrap" }}>
        {courseImages &&
          Object.keys(courseImages).length &&
          Object.keys(courseImages).map((key) => {
            if (key !== "length") {
              const fileObject = courseImages[key];
              return (
                <Box
                  key={key}
                  sx={{
                    height: "132px",
                    width: "132px",
                    borderRadius: "16px",
                    border: "1px solid #F1F1F1 ",

                    position: "relative",
                  }}
                >
                  <ImageComp
                    src={URL.createObjectURL(fileObject)}
                    sx={{
                      height: "100%",
                      width: "100%",
                      borderRadius: "10.56px",
                    }}
                  />
                  <IconButton
                    onClick={() => handleDelete(key)}
                    sx={{
                      color: "black",
                      width: "20.28px",
                      height: "20.28px",
                      marginBottom: "20 px",
                      position: "absolute",
                      top: "10px",
                      right: "10px",
                      background: "#323232",
                      padding: "2px",
                    }}
                  >
                    <CloseIcon
                      sx={{ color: "white", height: "15px", width: "15px" }}
                    />
                  </IconButton>
                </Box>
              );
            }
            return null;
          })}
      </Box> */}
      <Box sx={{ display: "flex", gap: "12px", flexWrap: "wrap" }}>
        {courseImages &&
          courseImages.length > 0 &&
          courseImages.map((file, index) => (
            <Box
              key={index}
              sx={{
                height: "132px",
                width: "132px",
                borderRadius: "16px",
                border: "1px solid #F1F1F1 ",
                position: "relative",
              }}
            >
              <ImageComp
                src={URL.createObjectURL(file)}
                sx={{
                  height: "100%",
                  width: "100%",
                  borderRadius: "10.56px",
                }}
              />
              <IconButton
                onClick={() => handleDelete(index)}
                sx={{
                  color: "black",
                  width: "20.28px",
                  height: "20.28px",
                  marginBottom: "20px",
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  background: "#323232",
                  padding: "2px",
                }}
              >
                <CloseIcon
                  sx={{ color: "white", height: "15px", width: "15px" }}
                />
              </IconButton>
            </Box>
          ))}
      </Box>

      <Box sx={{ display: "flex", justifyContent: "flex-end", mt: "24px" }}>
        <Button
          disabled={activeStep === 0}
          onClick={() => handleBack(activeStep)}
          sx={{ padding: "12px 40px", fontSize: "16px" }}
        >
          Back
        </Button>
        <Button
          variant="contained"
          onClick={() => handleNext(activeStep)}
          sx={{ padding: "12px 40px", color: "white", fontSize: "16px" }}
          disabled={checkLoading}
        >
          Next
        </Button>
      </Box>
    </Box>
  );
};

export default UploadPictures;
