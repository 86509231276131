import React from "react";
import { Box, Divider, IconButton, Typography } from "@mui/material";

import CallIcon from "@mui/icons-material/Call";
import MailIcon from "@mui/icons-material/Mail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Layout from "../Layout/Layout";
import theme from "../../../theme";
import { FooterLogo } from "../constants";
import {
  TwitterIcon,
  FacebookIcon,
  LinkedInIcon,
  YoutubeIcon,
  WhatsAppIcon,
  InstagramIcon,
} from "./../constants";
import { useNavigate } from "react-router-dom";
const Footer = () => {
  const navigate = useNavigate();
  return (
    <>
      <Box
        sx={{
          width: "100%",
          background: theme.palette.primary.main,
          mt: "25px",
        }}
      >
        <Layout>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              flexWrap: "wrap",
              padding: "30px 0",
              background: theme.palette.primary.main,
              [theme.breakpoints.down("md")]: {
                gap: "25px",
              },
            }}
          >
            <Box
              sx={{
                width: "100%",
                maxWidth: "381px",
              }}
            >
              <FooterLogo />
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  mt: { lg: "17px", md: "15px", sm: "12" },
                  gap: "6px",
                }}
              >
                <Typography variant="footerTitle">
                  Best Digital Platform of Pakistan!
                </Typography>
                <Typography variant="footerLinks" sx={{ fontSize: "16px" }}>
                  SMAC is a dynamic platform for continuous growth and talent
                  sharing. Our extensive courses enhance your knowledge and
                  skills in various fields. Engage in our vibrant marketplace to
                  buy products, sell services, and showcase your talents.
                  Connect with like-minded individuals and explore opportunities
                  through our integrated social media channels.
                </Typography>
              </Box>
            </Box>
            <Box>
              <Typography variant="footerLinkTitle">COMPANY</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: { lg: "20px", md: "14px" },
                  mt: { lg: "17px", md: "12px" },
                }}
              >
                <Typography
                  variant="footerLinks"
                  onClick={() => {
                    navigate("/about-us");
                  }}
                >
                  About Us
                </Typography>
                <Typography
                  variant="footerLinks"
                  onClick={() => {
                    navigate("/terms");
                  }}
                >
                  Privacy Policy
                </Typography>
                <Typography
                  variant="footerLinks"
                  onClick={() => {
                    navigate("/terms");
                  }}
                >
                  Terms of Use
                </Typography>
                {/* <Typography
                  variant="footerLinks"
                  onClick={() => {
                    navigate("/support");
                  }}
                >
                  Support
                </Typography> */}
              </Box>
            </Box>
            <Box>
              <Typography variant="footerLinkTitle">CONTACT US</Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: { lg: "20px", md: "14px" },
                  mt: { lg: "17px", md: "12px" },
                }}
              >
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <MailIcon sx={{ color: "white" }} />
                  <Typography variant="footerLinks" onClick={() => window.open("mailto:smaclimited0@gmail.com")}>
                  smaclimited0@gmail.com
                  </Typography>
                </Box>
                <Box sx={{ display: "flex", gap: "10px" }}>
                  <LocationOnIcon sx={{ color: "white" }} />
                  <Typography variant="footerLinks" onClick={() => window.open("https://maps.app.goo.gl/tJNZoyayJ8pCC1qu8")}>
                  395 DBlock phase 1 Johar Town Lahore
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box sx={{
              "@media(max-width:1180px)":{
                marginTop:"20px"
              }
            }}>
              <Typography variant="footerLinkTitle">Follow Us on:</Typography>
              <Box
                sx={{
                  display: "flex",
                  gap: "5.7px",
                  mt: { lg: "12px", md: "8px" },
                  "*:hover": {
                    cursor: "pointer",
                  },
                }}
              >
                <IconButton
                  onClick={() => window.open("https://x.com/SMAC_limited")}
                >
                  <TwitterIcon />
                </IconButton>{" "}
                <IconButton
                  onClick={() =>
                    window.open("https://www.facebook.com/smaclimited0")
                  }
                >
                  {" "}
                  <FacebookIcon />
                </IconButton>
                {/* <IconButton onClick={() => window.open("")}>
                  {" "}
                  <WhatsAppIcon />
                </IconButton> */}
                <IconButton
                  onClick={() =>
                    window.open("https://www.linkedin.com/company/znz-technologiess/")
                  }
                >
                
                  <LinkedInIcon />
                </IconButton>
                <IconButton
                  onClick={() =>
                    window.open("https://www.instagram.com/smaclimited0/")
                  }
                >
                  <InstagramIcon />
                </IconButton>
                <IconButton
                  onClick={() =>
                    window.open("https://www.youtube.com/@SMAC-Limited")
                  }
                >
                
                  <YoutubeIcon />
                </IconButton>
              </Box>
            </Box>
          </Box>
        </Layout>
        <Divider sx={{ backgroundColor: "white" }} />
        <Box
          sx={{
            padding: "28px 0px",
            width: "100%",
            background: theme.palette.primary.main,
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Typography variant="footerText">
            ©2024 SMACLTD | All Rights Reserved
          </Typography>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
