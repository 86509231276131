import React, { useEffect, useState } from "react";
import ProductCard from "../../../components/Market/Cards/Card";
import useFetch from "../../../features/hooks/useFetch";

const ProductsTab = () => {
  const { fetchData } = useFetch();
  const [productCardList, setProductCradList] = useState([]);
  useEffect(() => {
    fetchData("/api/product/my-products", undefined, (res) => {
      console.log(res, "My Products");
      setProductCradList(res?.data);
    });
  }, []);
  return (
    <div>
      {productCardList?.map((products) => {
        return <ProductCard cardData={products} key={products.productId} />;
      })}
    </div>
  );
};

export default ProductsTab;
