import { Box, Divider, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CourseCard from "./Cards/CourseCard";
import useFetch from "../../../features/hooks/useFetch";
import ButtonComp from "./../../globalComponents/ButtonComp";
import AddCourse from "../../Services/Modals/Add Course/AddCourses";
import { setCourseParentCategories } from "../../../features/slice/categoriesSlice";
import { useDispatch } from "react-redux";
import theme from "../../../theme";
import Layout from "../../globalComponents/Layout/Layout";
const UserCourses = () => {
  const [courseList, setCourseList] = useState([]);
  const [newCourse, setNewCourse] = useState("");
  const [courseOpen, setCourseOpen] = useState(false);
  const { fetchData } = useFetch();
  let dispatch = useDispatch();
  const getCourseCategories = async () => {
    await fetchData("/api/course/parent", undefined, (res) => {
      if (res) {
        dispatch(setCourseParentCategories({ data: res?.data }));
      }
    });
  };
  useEffect(() => {
    fetchData("/api/course/my-courses", undefined, (res) => {
      setCourseList(res?.data);
    });
    getCourseCategories();
  }, [newCourse]);
  return (
    <Layout styles={{ padding: "0" }}>
      <>
        <AddCourse addNewCourse={setNewCourse} open={courseOpen} setOpen={setCourseOpen} />
        <Box
          sx={{
            // maxWidth: "1252px",
            backgroundColor: "white",
            borderRadius: "20px",
            padding: "15px",
            height: "auto",
            width: "100%",
            [theme.breakpoints.down("md")]: {
              padding: "0px",
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              padding: "40px 20px 24px 20px",
            }}
          >
            <Typography variant="userDashboardHeading">Courses</Typography>
            <Box sx={{ width: "100%", maxWidth: "160px" }}>
              <ButtonComp
                icon={<AddIcon />}
                label="Add Course"
                click={() => {
                  setCourseOpen(!courseOpen);
                }}
              ></ButtonComp>
            </Box>
          </Box>
          <Divider />
          <Box
            sx={{
              padding: "40px",
              [theme.breakpoints.down("md")]: {
                padding: "16px",
              },
            }}
            component={"div"}
          >
            <Typography variant="postUserTypo">User courses details</Typography>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "20px",
                // gridTemplateColumns: "32% 32% 32%",
                // [theme.breakpoints.down("lg")]: {
                //   gridTemplateColumns: "50% 50%",
                // },
                // [theme.breakpoints.down("md")]: {
                //   gridTemplateColumns: "auto",
                //   justifyItems: "center",
                // },
                mt: "12px",
              }}
            >
              {courseList?.map((course) => {
                return (
                  <CourseCard
                    key={course.courseId}
                    item={course}
                    courseList={courseList}
                    setCourseList={setCourseList}
                    name={"myCourses"}
                  />
                );
              })}
            </Box>
          </Box>
        </Box>
      </>
    </Layout>
  );
};

export default UserCourses;
