import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import DasboardUserIcon from "../../../assets/users.png";
import MarketUserIcon from "../../../assets/markets.png";
import CoursesUserIcon from "../../../assets/courses.png";
import PaymentsUserIcon from "../../../assets/payments.png";
import RefendTicketsIcon from "../../../assets/RefundTickets.png";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import useMediaQuery from "@mui/material/useMediaQuery";
import Layout from "../../../components/globalComponents/Layout/Layout";
import DashboardUser from "./DashboardUser/DashboardUser";
import DashboardCourses from "./DashboardCourses/DashboardCourses";
import DashboardMarket from "./DashboardMarket/DashboardMarket";
import RefundTickets from "./RefundTickets/RefundTickets";
import { useNavigate, useLocation } from "react-router-dom";
import CategoriesPage from "./Categories/CategoriesPage";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3, width: "100%" }}>
          <Box>{children}</Box>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

function formatString(str) {
  return str.trim().replace(/\s+/g, "-");
}

const tabLabels = [
  "Users",
  "Courses",
  "Markets",
  "Payments",
  "Refund Tickets",
  "Categories",
];

const labelToIndex = tabLabels.reduce((acc, label, index) => {
  acc[formatString(label)] = index.toString();
  return acc;
}, {});

export default function AdminDashboard() {
  const location = useLocation();
  const navigate = useNavigate();
  const [value, setValue] = React.useState(() => {
    const query = new URLSearchParams(location.search).get("tab");
    return labelToIndex[query] ?? "0";
  });
  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleChange = (event, newValue) => {
    const label = tabLabels[parseInt(newValue)];
    const queryURL = formatString(label);
    setValue(newValue);
    navigate(`?tab=${queryURL}`);
  };

  React.useEffect(() => {
    const query = new URLSearchParams(location.search).get("tab");
    if (query && labelToIndex[query] !== value) {
      setValue(labelToIndex[query]);
    }
  }, [location.search, value]);

  const IconsStyle = {
    display: "flex",
    flexDirection: "row",
    alignItems: "start",
    justifyContent: "start",
  };

  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  return (
    <Layout
      styles={{
        minHeight: "90vh",
      }}
    >
      <Box
        sx={{
          marginTop: "20px",
          display: "flex",
          justifyContent: { xs: "center" },
          alignItems: { xs: "center", md: "start", lg: "start" },
          height: "100%",
          flexDirection: { xs: "column" },
          width: "100%",
        }}
      >
        {/* Hamburger menu button */}
        <Box
          sx={{
            width: "100%",
          }}
        >
          <IconButton
            sx={{ display: { xs: "block", md: "none", marginLeft: "4%" } }}
            onClick={() => setDrawerOpen(true)}
          >
            <MenuIcon />
          </IconButton>
        </Box>

        {/* Drawer for smaller screens */}
        <Drawer
          anchor="left"
          open={drawerOpen}
          onClose={() => setDrawerOpen(false)}
        >
          <Tabs
            orientation="vertical"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{
              borderRight: 1,
              borderColor: "divider",
              minWidth: "150px",
              display: isMobile ? "flex" : "none", // Only display on mobile
            }}
          >
            <Tab
              sx={IconsStyle}
              icon={
                <img
                  src={DasboardUserIcon}
                  alt="Logo"
                  style={{ margin: "0px 10px" }}
                />
              }
              label="Users"
              value="0"
              {...a11yProps(0)}
            />
            <Tab
              sx={IconsStyle}
              icon={
                <img
                  src={CoursesUserIcon}
                  alt="Logo"
                  style={{ margin: "0px 10px" }}
                />
              }
              label="Courses"
              value="1"
              {...a11yProps(1)}
            />
            <Tab
              sx={IconsStyle}
              icon={
                <img
                  src={MarketUserIcon}
                  alt="Logo"
                  style={{ margin: "0px 10px" }}
                />
              }
              label="Markets"
              value="2"
              {...a11yProps(2)}
            />
            <Tab
              sx={IconsStyle}
              icon={
                <img
                  src={PaymentsUserIcon}
                  alt="Logo"
                  style={{ margin: "0px 10px" }}
                />
              }
              label="Payments"
              value="3"
              {...a11yProps(3)}
            />
            <Tab
              sx={IconsStyle}
              icon={
                <img
                  src={RefendTicketsIcon}
                  alt="Logo"
                  style={{ margin: "0px 10px" }}
                />
              }
              label="Refund Tickets"
              value="4"
              {...a11yProps(4)}
            />
          </Tabs>
        </Drawer>
        {/* Content */}
        <Box
          sx={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-start",
            width: "100%",
          }}
        >
          {/* Tabs for larger screens */}
          <Tabs
            orientation="vertical"
            value={value}
            onChange={handleChange}
            aria-label="Vertical tabs example"
            sx={{
              borderRight: 1,
              borderColor: "divider",
              minWidth: "150px",
              width: "405px",
              "@media(max-width:1024px)": {
                width: "365px",
              },
              // width:"100%",
              display: isMobile ? "none" : "flex", // Only display on desktop
            }}
          >
            <Tab
              sx={IconsStyle}
              icon={
                <img
                  src={DasboardUserIcon}
                  alt="Logo"
                  style={{ margin: "0px 10px" }}
                />
              }
              label="Users"
              value="0"
              {...a11yProps(0)}
            />
            <Tab
              sx={IconsStyle}
              icon={
                <img
                  src={CoursesUserIcon}
                  alt="Logo"
                  style={{ margin: "0px 10px" }}
                />
              }
              label="Courses"
              value="1"
              {...a11yProps(1)}
            />
            <Tab
              sx={IconsStyle}
              icon={
                <img
                  src={MarketUserIcon}
                  alt="Logo"
                  style={{ margin: "0px 10px" }}
                />
              }
              label="Markets"
              value="2"
              {...a11yProps(2)}
            />
            <Tab
              sx={IconsStyle}
              icon={
                <img
                  src={PaymentsUserIcon}
                  alt="Logo"
                  style={{ margin: "0px 10px" }}
                />
              }
              label="Payments"
              value="3"
              {...a11yProps(3)}
            />
            <Tab
              sx={IconsStyle}
              icon={
                <img
                  src={RefendTicketsIcon}
                  alt="Logo"
                  style={{ margin: "0px 10px" }}
                />
              }
              label="Refund Tickets"
              value="4"
              {...a11yProps(4)}
            />
            <Tab
              sx={IconsStyle}
              icon={
                <img
                  src={RefendTicketsIcon}
                  alt="Logo"
                  style={{ margin: "0px 10px" }}
                />
              }
              label="Categories"
              value="5"
              {...a11yProps(5)}
            />
          </Tabs>

          <Box sx={{ width: "100%" }}>
            <TabPanel value={parseInt(value, 10)} index={0}>
              <Box>
                <DashboardUser />
              </Box>
            </TabPanel>
            <TabPanel value={parseInt(value, 10)} index={1}>
              <Box>
                <DashboardCourses />
              </Box>
            </TabPanel>
            <TabPanel value={parseInt(value, 10)} index={2}>
              <Box>
                <DashboardMarket />
              </Box>
            </TabPanel>
            <TabPanel value={parseInt(value, 10)} index={3}>
              <Box></Box>
            </TabPanel>
            <TabPanel value={parseInt(value, 10)} index={4}>
              <Box>
                <RefundTickets />
              </Box>
            </TabPanel>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}
