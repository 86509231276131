import { combineReducers, configureStore } from "@reduxjs/toolkit";
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";
import storage from "redux-persist/lib/storage";

import userReducer from "../features/slice/userSlice";
import postSlice from "../features/slice/postSlice";
import followSlice from "../features/slice/followSlice";
import addServiceSlice from "../features/slice/addServiceSlice";
import DashBoardProductsSlice from "../features/slice/DashBoardProductsSlice";
import viewProductSlice from "../features/slice/Market/viewProductSlice";
import subscriptionPriceSlice from "../features/slice/Payment/subscriptionPriceSlice";
import addProductSlice from "../features/slice/addProductSlice";
import autoCompleteSlice from "../features/slice/autoCompleteSlice";
import socialModelsSlice from "../features/slice/Social/socialModelsSlice";
import multiStateUserIntrestsSlice from "../features/slice/Social/multiStateUserIntrestsSlice";
import categoriesSlice from "../features/slice/categoriesSlice";
import globalSearchSlice from "../features/slice/globalSearchSlice";
export const rootReducer = combineReducers({
  user: userReducer,
  post: postSlice,
  addService: addServiceSlice,
  follow: followSlice,
  DashBoardProductsSlice: DashBoardProductsSlice,
  viewProduct: viewProductSlice,
  subscriptionPrice: subscriptionPriceSlice,
  addProduct: addProductSlice,
  autoComplete: autoCompleteSlice,
  socialModels: socialModelsSlice,
  multiStateUserIntrests: multiStateUserIntrestsSlice,
  parentCategories: categoriesSlice,
  globalSearch: globalSearchSlice,
});

const persistConfig = {
  key: "root",
  storage,
};

const persitedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persitedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
const persistor = persistStore(store);

export { store, persistor };
