import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  price: null,
};

const subscriptionPriceSlice = createSlice({
  name: "price",
  initialState,
  reducers: {
    setPrice: (state, action) => {
      state.price = action.payload.price;
    },
  },
});

export const { setPrice } = subscriptionPriceSlice.actions;
export default subscriptionPriceSlice.reducer;
