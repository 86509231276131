import React, { useState } from "react";
import Layout from "../../../components/globalComponents/Layout/Layout";
import { Box, Typography, Tab } from "@mui/material";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import Followers from "./Followers";
import Following from "./Following";
import Invitation from "./Invitation";
import ProfilePicture from "../../../components/globalComponents/ProfilePicture";

const Connections = () => {
  const [value, setValue] = useState("1");
  // const isMobile = useMediaQuery("(max-width:600px)"); // Define your breakpoint for mobile
  //change tab value
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Layout title={"Your Social Network | SMAC"}>
      {/*  */}
      {/*Followers or Cards section */}
      <Box
        sx={{
          bgcolor: "#FFFFFF",
          borderRadius: "10px",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          marginTop: "20px",
        }}
      >
        <Box
          sx={{
            width: "100%",
          }}
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <TabList onChange={handleChange} centered>
                {/* tab buttons? */}
                <Tab
                  label={
                    <Typography variant="h3" sx={{ width: "200px" }}>
                      Followers
                    </Typography>
                  }
                  value="1"
                />
                <Tab
                  label={
                    <Typography variant="h3" sx={{ width: "200px" }}>
                      Following
                    </Typography>
                  }
                  value="2"
                />
                <Tab
                  label={
                    <Typography variant="h3" sx={{ width: "200px" }}>
                      Invitation
                    </Typography>
                  }
                  value="3"
                />
              </TabList>
            </Box>
            <TabPanel value="1">
              <Box>
                <Followers />
              </Box>
            </TabPanel>
            <TabPanel value="2">
              <Following />
            </TabPanel>
            <TabPanel value="3">
              <Invitation />
            </TabPanel>
          </TabContext>
        </Box>
      </Box>
    </Layout>
  );
};

export default Connections;
