import { Box, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../features/slice/userSlice";
import { googleLogout } from "@react-oauth/google";
import ProfilePicture from "../../components/globalComponents/ProfilePicture";

// getting onClose method for closing the menu
const ProfileDropdown = ({ onClose }) => {
  //get user and token from store after login
  const { user, token } = useSelector((state) => state.user);
  // const token=useSelector((state)=>state.user.token)
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // console.log(user,"user",token,"token");

  //if user not logged In and have no tokken then logout
  useEffect(() => {
    if (!user || !token) {
      dispatch(logout());
      // navigate("/signin")
    }
  }, []);

  // console.log(user);

  const storyProfileStyles = {
    backgroundSize: "cover",
    objectFit: "cover",
    borderRadius: "50%",
    padding: "5px",
    width: "70px",
    height: "70px",
    verticalAlign: "middle",
    zIndex: 999,
    position: "relative",
    top: "35px",
    left: "40%",
  };
  const linkStyles = {
    color: "#868686",
    fontSize: "16px",
    textDecoration: "none",
  };

  // logout user
  const handleSignOut = () => {
    dispatch(logout());
    googleLogout();
    onClose();
  };
  return (
    <Box
      sx={{
        width: "379px",

        // boxShadow: "0px 4px 60px 0px rgba(0, 0, 0, 0.25)",
        // position: "absolute",
        // border: "1px solid black",
        // position: "relative",
      }}
    >
      {/* if user loggedIn then display user details */}
      {user ? (
        <>
          <Box
            sx={{
              //background to be added dynamically as user cover photo
              backgroundImage: `url(${user.coverPic})`,
              height: "81px",
              backgroundSize: "cover",
              marginBottom: "30px",
              margin: "10px",
            }}
          >
            <ProfilePicture
              src={user.profilePic}
              firstName={user.firstName}
              sx={storyProfileStyles}
              innerBox={{
                height: "58px",
                width: "58px",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              gap: "10px",
              padding: "10px",
            }}
          >
            {/* display user details */}
            <Typography variant="addStoryTypo">{user?.email}</Typography>
            <Typography
              variant="postUserTypo"
              sx={{ textTransform: "capitalize", wordBreak: "break-all" }}
            >
              {user?.firstName?.substring(0, 10) +
                "  " +
                user?.lastName?.substring(0, 10)}
            </Typography>
          </Box>
          <hr style={{ background: "#F1F1F1", opacity: "0.5" }} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "10px 20px",
              gap: "15px",
              // borderBottom: "1px solid black",
            }}
          >
            <NavLink style={linkStyles} to={"profile-user"} onClick={onClose}>
              Profile
            </NavLink>
            {user?.role === "admin" ? (
              <NavLink
                style={linkStyles}
                onClick={onClose}
                to={"admin/dashboard"}
              >
                Admin Dashboard
              </NavLink>
            ) : null}
            <NavLink
              style={linkStyles}
              onClick={onClose}
              to={`user/dashboard/${user?.email}`}
            >
              Dashboard
            </NavLink>
            <NavLink style={linkStyles} onClick={onClose} to={`settings`}>
              Settings
            </NavLink>
          </Box>
          <hr style={{ background: "#F1F1F1", opacity: "0.5" }} />
          <Box
            sx={{
              padding: "10px 20px",
            }}
          >
            <NavLink style={linkStyles} onClick={handleSignOut}>
              Signout
            </NavLink>
          </Box>
        </>
      ) : (
        // if user not loggedIn then show signin and signup
        <>
          {/* <Box
            sx={{
              //background to be added dynamically as user cover photo
              backgroundColor: "lightgray",
              height: "81px",
              backgroundSize: "cover",
              marginBottom: "30px",
              margin: "10px",
            }}
          >
            <ImageComp
              src={"assets/photos/story.png"}
              alt={"story_image"}
              sx={storyProfileStyles}
            />
          </Box> */}

          {/* <hr style={{ background: "#F1F1F1", opacity: "0.5" }} /> */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              padding: "10px 20px",
              gap: "15px",
              // borderBottom: "1px solid black",
            }}
          >
            <NavLink style={linkStyles} to={"signin"} onClick={onClose}>
              SignIn
            </NavLink>
          </Box>
          {/* <hr style={{ background: "#F1F1F1", opacity: "0.5" }} /> */}
          <Box
            sx={{
              padding: "10px 20px",
            }}
          >
            <NavLink style={linkStyles} to={"signup"} onClick={onClose}>
              SignUp
            </NavLink>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ProfileDropdown;
