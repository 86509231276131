import { Box, Typography } from "@mui/material";
import React from "react";
import theme from "../../theme";
import Layout from "./Layout/Layout";

const Banner = ({ title, placement, text, image }) => {
  return (
    <>
      <Box
        sx={{
          backgroundImage: `linear-gradient(to right, rgba(20, 184, 166, .9), rgba(20, 184, 166, .9)), url('${image}')`,
          backgroundColor: theme.palette.primary.main,
          [theme.breakpoints.down("lg")]: {
            marginBottom: "30px",
          },
        }}
      >
        <Layout
          styles={{
            display: "flex",
            justifyContent: placement ? placement : "center",
            padding: "86px 16px 146px 16px",
            marginBottom: "80px",
          }}
        >
          <Box
            sx={{
              width: "100%",
              maxWidth: "928px",
              textAlign: placement ? placement : "center",
              padding: "0 24px",
            }}
          >
            <Typography variant="bannerTitle">{title}</Typography>
            {text ? (
              <Typography sx={{ color: "#FFFFFF", marginTop: "24px" }}>
                {text}
              </Typography>
            ) : null}
          </Box>
        </Layout>
      </Box>
    </>
  );
};

export default Banner;
