import React, { useState } from "react";
import { Box, Card, CardMedia, CardContent, Typography } from "@mui/material";
import ViewButton from "../Button/ViewButton";
// import { useDispatch, useSelector } from "react-redux";
// import {
//   setIsViewProductOpen,
//   setProduct,
// } from "../../../features/slice/Market/viewProductSlice";
import { useNavigate } from "react-router-dom";
const ProductCard = ({ cardData, index }) => {
  const [show, setShow] = useState(false);
  // const { isViewProductOpen } = useSelector((state) => state.viewProduct);
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  // const handleViewProduct = (details) => {
  //   //function to open product details and set single product data to redux
  //   dispatch(setIsViewProductOpen({ open: !isViewProductOpen }));
  //   dispatch(setProduct({ product: details }));
  // };
  return (
    <Card
      key={index}
      sx={{
        display: "block",
        height: "462px",
        width: "100%",
        maxWidth: "404px",
        boxShadow: "0px 2px 30px -1px rgba(0,0,0,0.1) !important",
        "@media(max-width:375px)": {
          maxWidth: "250px",
        },
      }}
    >
      <Box
        sx={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
        }}
        onMouseEnter={() => setShow(true)}
        onMouseLeave={() => setShow(false)}
        onClick={() => {
          // handleViewProduct(cardData);
          navigate(`${cardData?.productId}`);
        }}
      >
        <Box sx={{ position: "absolute" }}>
          <ViewButton product={cardData} show={show} />
        </Box>
        <CardMedia
          component="img"
          height="140"
          sx={{
            width: "404px",
            height: "245px",
            objectFit: "cover",
          }}
          src={cardData.images[0]}
          alt="green iguana"
        />
      </Box>

      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
          height: "217px",
          justifyContent: "space-between",
        }}
      >
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexWrap: "wrap",
            }}
          >
            <Typography gutterBottom variant="bold24Black">
              {cardData.title.length > 5
                ? cardData.title.substring(0, 5) + "....."
                : cardData.title}
            </Typography>
            <Typography
              variant="contained"
              style={{
                borderRadius: "20px",
                width: "max-content",
                background: "#0EA6E9",
                padding: "10px",
                color: "white",
              }}
            >
              $ &nbsp;{cardData.price}
            </Typography>
          </Box>
          <Typography
            variant="body2"
            color="text.secondary"
            sx={{ paddingTop: "5px", wordBreak: "break-all" }}
          >
            {cardData?.description.length > 100
              ? cardData.description.substring(0, 100) + "....."
              : cardData.description}
          </Typography>
        </Box>
        <Typography size="small" color="primary">
          {cardData.createdAt.substring(0, 10)}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default ProductCard;
